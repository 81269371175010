import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActionInst, ActionType, ExtraColumnType, TableModel } from '../../models/grid.model';
import { ProxyService } from '../../services/proxy.service';
import { PagedResponse } from '../../models/paged-response.model';
import { ToastService } from '../../services/toast.service';
import { DateUtility } from '../../utils/date.utility';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseModel } from 'app/shared/models/base.model';
import { ReplaySubject, Subject } from 'rxjs';
import { DataTransferService } from '../../services/data-transfer.service';
import { SelectionModel } from '@angular/cdk/collections';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Value } from '../../models/value.model';
import { HttpClient } from '@angular/common/http';
import { hasError } from '../../utils/errors.utility';

@Component({
  selector: 'app-data-selection-table',
  templateUrl: './data-selection-table.component.html',
  styleUrls: ['./data-selection-table.component.scss']
})
export class DataSelectionTableComponent<T extends Value> implements AfterViewInit {
  mediaUrl = environment.imageUrl;
  dateUtility = new DateUtility();
  actionType = ActionType;
  skip = 1;

  selectedRow = null;
  rowChanged: Subject<any> = new Subject<any>();

  currentPage = 1;
  pageSize = 10;
  extraColumnTypes = ExtraColumnType;
  // rows: BaseModel[];
  rows: any;
  total: number;

  @Input() callbackFunction: (args: any) => any;
  @Input() data: TableModel<any>;
  @Input() defaultData: any[];
  @Input() pagination = true
  // TODO Желательно переделать на Observable
  // @Input() public columns$: Observable<TableColumnModel<T>[]> = of([]);
  // @Input() public extraColumns$: Observable<TableColumnModel<T>[]> = of([]);
  // @Input() public data$: Observable<T[]> = of([]);
  //
  //
  // TODO Заменить any на T[] когда будет использоваться dataSource вместо row
  @Output() public selectionChanged = new EventEmitter<any>();


  dataSource = new MatTableDataSource<BaseModel>(this.rows);
  selection = new SelectionModel<BaseModel>(true, []);

  private readonly onDestroy$ = new ReplaySubject<null>(1);

  constructor(private proxy: ProxyService,
              private ref: ChangeDetectorRef,
              private toast: ToastService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private http: HttpClient,
              private dataTransferService: DataTransferService) {

  }

  // async getProductsFromStripe() {
  //   const authKey = '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';
  //   const authHeader = new HttpHeaders({ 'Authorization': `Bearer ${authKey}` });
  //   const url = 'https://api.stripe.com/v1';
  //   return await this.http
  //       .get(`${url}/products`, {headers: authHeader})
  //       .toPromise();
  // }

  async getProductsFromStripe() {
    // const authKey = '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';
    // const authHeader = new HttpHeaders({ 'Authorization': `Bearer ${authKey}` });
    // const url = 'https://api.stripe.com/v1';
    // const playgroundId = 1;
    // return await this.http
    //   .get(`${environment.url}stripe/additional_products/${playgroundId}`, {headers: null})
    //   .toPromise();
  }

  async getGridResult() {
    this.selection.clear();
    this.data.filter.take = this.pageSize;
    if (!this.pagination) {
      delete this.data.filter.take;
    }
    if (this.data.filter.skip == null) {
      this.data.filter.skip = 1;
    }

    this.proxy.get<PagedResponse<any>>(this.data.sourceUrl, this.data.filter, null)
      .subscribe(res => {
        if (!hasError(res)) {
          this.setData(res.data.entities);
          this.ref.detectChanges();
        }
      });
  }

  private setData(data) {
    if (this.callbackFunction && typeof this.callbackFunction === 'function') {
      this.callbackFunction(data);
    }
    this.rows = data;
    this.total = data.length;
  }

  rowSelected(row) {
    this.selectedRow = row;
    this.rowChanged.next(this.selectedRow);
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.rows.forEach(row => this.selection.select(row));
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.rows.length;
    return numSelected === numRows;
  }

  clearSelection() {
    this.selection.clear();
  }

  // checkboxLabel(row?: BaseModel): string {
  //   if (!row) {
  //     return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
  //   }
  //   return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  // }

  log() {
    console.log(this.dataSource)
    console.log(this.selection)
  }

  loadPage(data) {
    this.router.navigate(['.'], {relativeTo: this.activatedRoute, queryParams: {skip: data}});
  }

  async delete(row) {
    this.toast.delete(
      await this.proxy.post(
        this.data.actions.actions.find(a => a.action === ActionType.Delete).url, {id: row.id}, null), () => {
        this.getGridResult();
      });
  }

  dublicate(row) {

  }

  public getFiles() {
    // console.log('get files');
  }

  getAction(type: ActionType): ActionInst {
    return this.data.actions.actions.find(x => x.action === type);
  }

  async setSourceAndNavigate(type: ActionType, row: any) {
    const url = this.getAction(type).url;
    this.dataTransferService.data = row
    await this.router.navigate([url + `/${row.id}`]);
  }

  ngAfterViewInit(): void {
    this.activatedRoute.queryParams.subscribe(async params => {
      if (params.skip != null) {
        this.skip = +params.skip;
        this.data.filter.skip = +params.skip;
      }
      if (this.defaultData) {
        this.setData(this.defaultData);
        return;
      }
      this.currentPage = this.skip;
      this.ref.detectChanges();
    });
    this.onSelection();
  }

  private onSelection() {
    this.selection.changed
      .pipe(distinctUntilChanged(), takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.selectionChanged.emit(this.selection.selected)
      } );
  }

  // TODO заготовка для Obserable, для постоянной подгрузки (для не постраничного отобажения)
  // private onScroll() {
  //   this.scrollDispatcher
  //     .scrolled()
  //     .pipe(
  //       filter(() => this.viewport.measureScrollOffset('bottom') === 0),
  //       debounceTime(TableComponent.debounceMs),
  //       takeUntil(this.onDestroy$),
  //     )
  //     .subscribe(() => this.dataNeeded.emit());
  // }
  //
  // private onDataChanges() {
  //   this.rows.subscribe(data => {
  //     this.selection.deselect(...this.selection.selected.filter(item => !data.includes(item)));
  //     this.dataSource.data = data;
  // })}
}

