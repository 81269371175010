import { Component, OnInit, Injector, Input, ViewChild, ElementRef, Output, EventEmitter, HostBinding, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropDownComponent  {
    @HostBinding('class') class = 'col-12 col-sm-6 col-lg-3';
    @Input() multiple: boolean;

    name: any;
    value: any;
    defaultValue: any;
    source: any;
}
