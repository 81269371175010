import {ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-timer',
  templateUrl: 'timer.component.html',
  styleUrls: ['timer.component.scss']
})
export class TimerComponent {
  @Output() successTimer = new EventEmitter();
  @Input() title = 'Повторить';
  @Input() minutes = 1;
  @Input() seconds = 0;
  @Input() delay = 1000;

  timerEndTime = this.minutes * 60_000;

  constructor(private ref: ChangeDetectorRef) {
  }

  public startTimer() {
    const timerId = setInterval(() => {
      if (this.seconds === 0) {
        if (this.minutes !== 0) {
          this.minutes -= 1;
          this.seconds = 59;
        }
      } else {
        this.seconds -= 1;
      }

      if (this.minutes === 0 && this.seconds === 0) {
        this.seconds = 0;
        this.minutes = 1;
      }
      this.ref.detectChanges();
    }, this.delay);

    setTimeout(() => {
      clearInterval(timerId);
      this.successTimer.emit();
    }, this.timerEndTime);
  }
}
