import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output, SimpleChanges} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActionInst, ActionType, ExtraColumnType, TableModel } from '../../models/grid.model';
import { ProxyService } from '../../services/proxy.service';
import { PagedResponse } from '../../models/paged-response.model';
import { ToastService } from '../../services/toast.service';
import { DateUtility } from '../../utils/date.utility';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseModel } from 'app/shared/models/base.model';
import { ReplaySubject, Subject } from 'rxjs';
import { DataTransferService } from '../../services/data-transfer.service';
import { SelectionModel } from '@angular/cdk/collections';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Value } from '../../models/value.model';
import { HttpClient } from '@angular/common/http';
import { hasError } from '../../utils/errors.utility';

@Component({
  selector: 'app-data-select-table',
  templateUrl: './data-select-table.component.html',
  styleUrls: ['./data-select-table.component.scss']
})
export class DataSelectTableComponent<T extends Value> implements AfterViewInit {
  mediaUrl = environment.imageUrl;
  dateUtility = new DateUtility();
  actionType = ActionType;
  skip = 1;

  selectedRow = null;
  rowChanged: Subject<any> = new Subject<any>();

  currentPage = 1;
  pageSize = 10;
  extraColumnTypes = ExtraColumnType;
  rows: any;
  total: number;

  @Input() callbackFunction: (args: any) => any;
  @Input() data: TableModel<any>;
  @Input() defaultData: any[];
  @Input() pagination = true
  @Output() public selectionChanged = new EventEmitter<any>();


  dataSource = new MatTableDataSource<BaseModel>(this.rows);
  selection = new SelectionModel<BaseModel>(true, []);

  private readonly onDestroy$ = new ReplaySubject<null>(1);
  public innerWidth: number;
  public isSelected = false;

  constructor(private proxy: ProxyService,
              private ref: ChangeDetectorRef,
              private toast: ToastService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private http: HttpClient,
              private dataTransferService: DataTransferService)
  {
    this.innerWidth = window.innerWidth;
  }

  async getGridResult() {
    this.selection.clear();
    this.data.filter.take = this.pageSize;
    if (this.data.filter.skip == null) {
      this.data.filter.skip = 1;
    }
    if (!this.pagination) {
      delete this.data.filter.take;
      delete this.data.filter.skip;
    }
    if (this.defaultData) {
      console.log(this.defaultData)
      this.setData(this.defaultData);
      return;
    }

    this.proxy.get<PagedResponse<any>>(this.data.sourceUrl, this.data.filter, null)
      .subscribe(res => {
        if (!hasError(res)) {
          this.setData(res.data.entities);
          this.ref.detectChanges();
        }
      });
  }

  private setData(data) {
    if (this.callbackFunction && typeof this.callbackFunction === 'function') {
      this.callbackFunction(data);
    }
    this.rows = data;
    this.total = data.length;
  }

  clearSelection(row?: any) {
    this.rows.forEach(r => {
      if (r !== row) {
        r.isSelected = false;
      }
    });
    if (row) {
      row.isSelected = false
    }
    this.selectedRow = null
    this.selectionChanged.emit(this.selectedRow)
  }

  log() {
    console.log(this.dataSource)
    console.log(this.selection)
  }

  loadPage(data) {
    this.router.navigate(['.'], {relativeTo: this.activatedRoute, queryParams: {skip: data}});
  }

  async delete(row) {
    this.toast.delete(
      await this.proxy.post(
        this.data.actions.actions.find(a => a.action === ActionType.Delete).url, {id: row.id}, null), () => {
        this.getGridResult();
      });
  }

  dublicate(row) {

  }

  public getFiles() {
  }

  getAction(type: ActionType): ActionInst {
    return this.data.actions.actions.find(x => x.action === type);
  }

  async setSourceAndNavigate(type: ActionType, row: any) {
    const url = this.getAction(type).url;
    this.dataTransferService.data = row
    await this.router.navigate([url + `/${row.id}`]);
  }

  ngAfterViewInit(): void {
    this.activatedRoute.queryParams.subscribe(async params => {
      if (params.skip != null) {
        this.skip = +params.skip;
        this.data.filter.skip = +params.skip;
      }
      await this.getGridResult();
      this.currentPage = this.skip;
      this.ref.detectChanges();
    });
    this.onSelection();
  }

  private onSelection() {
    this.selection.changed
      .pipe(distinctUntilChanged(), takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.selectionChanged.emit(this.selection.selected)
      } );
  }

  toggleSelection(row: any) {
    this.rows.forEach(r => {
      if (r !== row) {
        r.isSelected = false;
      }
    });
    row.isSelected = !row.isSelected
    if (this.selectedRow == row) {
      this.clearSelection(row)
    } else {
      this.selectedRow = row
      this.rowChanged.next(this.selectedRow);
    }
    this.emitSelectionChange()
  }

  emitSelectionChange() {
    this.selectionChanged.emit(this.selectedRow)
  }
}

