import {Component, HostBinding, OnInit} from '@angular/core';

@Component({
  selector: 'app-multiselect-filter',
  templateUrl: './multiselect-filter.component.html',
  styleUrls: ['./multiselect-filter.component.scss']
})
export class MultiselectFilterComponent {
  @HostBinding('class') class = 'col-12 col-sm-6 col-lg-3';

  public name: string;
  public value: string;
  public source: any;
}
