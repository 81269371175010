<ng-select [class.customError]="isInvalid" #searchinput [multiple]="multiple" [(ngModel)]="value" readonly="{{readonly}}" (ngModelChange)="onChangeItem()" [clearable]="!disableClear" (clear)="getData('');" [ngModelOptions]="{standalone: true}">
  <ng-option *ngFor="let m of items" [value]="m[propName]">
    <div class="selected_item">
      <span *ngIf="hasColor" class="dot " [style.background-color]="m.value"></span>
      <div class="col-6">{{m[propDisplay] | translate}}</div>

    </div>

  </ng-option>
</ng-select>
