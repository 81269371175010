export class GridModel<T> {
  headers: GridHeader[];
  actions?: ActionObject;
  sourceUrl: string;
  filter?: T;
}

export class TableModel<T> extends GridModel<T> {
  extraColumns: ExtraColumnHeader[] = [];
}

export class ExtraColumnHeader {
  property: string;
  type: ExtraColumnType;
}

export class GridHeader {
  displayName: string;
  property: string;
  type: DisplayType;
  trueLabel?: string;
  falseLabel?: string;
}

export class ActionObject {
  displayName: string;
  actions: ActionInst[];
}

export class ActionInst {
  url?: string;
  action: ActionType;
  func?: Function;
  text?: string;
}

export enum ActionType {
  Edit = 1,
  Delete = 2,
  Duplicate = 3,
  Chat = 4,
  Close = 5,
  Details = 6,
  UnpaidOrder = 6,
  RepeatFiscal = 7,
  FindReceipt = 8
}

export enum FilterType {
  Skip = 1,
  Take = 10
}

export enum DisplayType {
  Image = 'image',
  QR = 'qr',
  Boolean = 'boolean',
  DateTime = 'datetime',
  Link = 'link',
  Percente = 'percente',
  HTML = 'html',
  File = 'file',
  FiscalRegisterStatus = 'fiscalRegisterStatus',
  List = 'list'
}

export enum ExtraColumnType {
  Selection = 'select',
}
