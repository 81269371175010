import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { FilterPipe } from "./filter.pipe";
import { SearchPipe } from "./search.pipe";
import { ShortNamePipe } from "./short-name.pipe";
import { FormattedDatePipe } from "./formatted-date.pipe";
import { GridRowValuePipe } from "./grid-row-value.pipe";
import { UrlImagePipe } from "./url-image.pipe";
import { RemoveHtmlTagsPipe } from "./remove-html-tags.pipe";
import { TranslationPipe } from "./translation.pipe";
import {TimerPipe} from './timer.pipe';

@NgModule({
  declarations: [
    FilterPipe,
    SearchPipe,
    ShortNamePipe,
    FormattedDatePipe,
    GridRowValuePipe,
    UrlImagePipe,
    RemoveHtmlTagsPipe,
    TranslationPipe,
    TimerPipe
  ],
  imports: [CommonModule],
  exports: [
    FilterPipe,
    SearchPipe,
    ShortNamePipe,
    FormattedDatePipe,
    GridRowValuePipe,
    UrlImagePipe,
    RemoveHtmlTagsPipe,
    TranslationPipe,
    TimerPipe
  ],
})
export class PipeModule {}
