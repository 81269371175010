export type LanguageType = {
  id: number,
  icon: string,
  value: string,
  key: string
};

export const Languages: LanguageType[] = [
  {
    id: 1,
    key: 'English',
    value: 'en',
    icon: 'en.svg'
  },
  {
    id: 2,
    key: 'Русский',
    value: 'ru',
    icon: 'ru.svg'
  },
  {
    id: 3,
    key: 'हिंदी',
    value: 'hi',
    icon: 'hi.svg'
  },
  {
    id: 4,
    key: 'Bahasa Indonesia',
    value: 'id',
    icon: 'id.svg'
  },
  {
    id: 5,
    key: 'Filipino',
    value: 'fil',
    icon: 'fil.svg'
  },
  {
    id: 6,
    key: 'คนไทย',
    value: 'th',
    icon: 'th.svg'
  },
  {
    id: 7,
    key: 'Azərbaycan',
    value: 'az',
    icon: 'az.svg'
  },
  {
    id: 8,
    key: 'қазақ',
    value: 'kk',
    icon: 'kk.svg'
  },
  {
    id: 9,
    key: '中国人',
    value: 'cn',
    icon: 'cn.svg'
  },
  {
    id: 10,
    key: 'العربية',
    value: 'ar',
    icon: 'ar.svg'
  },
  {
    id: 11,
    key: 'Española',
    value: 'es',
    icon: 'en.svg'
  },
  {
    id: 12,
    key: 'Deutsch',
    value: 'de',
    icon: 'de.svg'
  },
  {
    id: 13,
    key: 'Français',
    value: 'fr',
    icon: 'fr.svg'
  },
  {
    id: 14,
    key: 'Português',
    value: 'pt',
    icon: 'pt.svg'
  }
]
