import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { StorageService, StorageValue } from '../services/storage.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let token = StorageService.get(StorageValue.User)?.token;
    if (token == null || token?.trim() == '') {
      this.router.navigate(['/login']);
    }
    else {
      return true;
    }
  }
}
