import {Injectable} from '@angular/core';
import {ProxyService, Urls} from './proxy.service';
import { BehaviorSubject } from 'rxjs';
import { hasError } from '../utils/errors.utility';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
// @ts-ignore
export class WorkChatService {

  private  countUnreadChats$ = new BehaviorSubject<number>(0);

  constructor(private proxyService: ProxyService) {
    this.setCountUnreadChats();
  }

  getChatById(id: number) {
    return this.proxyService.get<any>(`${Urls.WorkChatsById}`, {chatId: id}, null);
  }

  getChatByManagerId(id: number) {
    return this.proxyService.get<any>(`${Urls.WorkChatsByManagerId}`, {managerId: id}, null);
  }

  markMessagesAsRead(id: number) {
    return this.proxyService.post<any>(`${Urls.WorkChatsMarkReadUpdate}?chatId=${id}`, {}, null);
  }

  getCountUnreadChats() {
    return this.countUnreadChats$;
  }

  public setCountUnreadChats() {
    return this.proxyService.get<number>(Urls.WorkChatsUnread, {}, null)
      .pipe(
        map(res => {
          if (!hasError(res)) {
            this.countUnreadChats$.next(res.data);
          }
          return res;
        })
      );
  }
}
