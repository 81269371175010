import { Component, OnInit, HostBinding, Output, EventEmitter, HostListener} from '@angular/core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-date-picker-from-to',
  templateUrl: './date-picker-from-to.component.html',
  styleUrls: ['./date-picker-from-to.component.scss']
})
export class DatePickerFromToComponent implements OnInit {

    constructor(private datePipe: DatePipe) { }

  ngOnInit(): void {
  }
    @HostBinding('class') class = 'col-12 col-sm-6 col-lg-6';
    @Output() onDatepickerEnter: EventEmitter<boolean> = new EventEmitter<boolean>()

    nameTo: any;
    nameFrom: any;
    valueForBindingTo: any;
    valueForBindingFrom: any;
    valueTo = null ;
    valueFrom = null ;
    minDateTo = {} as any;
    maxDateTo = {} as any;
    minDateFrom = {} as any;
    maxDateFrom = {} as any;

    onChangeFrom(data) {
      this.valueFrom = data?.year + '-' + data?.month + '-' + data?.day + "T00:00:00";
      this.minDateTo = data;      
    }
    onChangeTo(data) {
      this.valueTo = data?.year + '-' + data?.month + '-' + data?.day + "T23:59:59";
      this.maxDateFrom = data;
    }
}
