import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

import { QuillEditorComponent } from 'ngx-quill';
import { debounceTime,  distinctUntilChanged} from 'rxjs/operators';
import { Validator } from 'app/shared/validators/validators';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss']
})
export class EditorComponent implements OnInit, AfterViewInit {
  @Output() onChangeEditorData: EventEmitter<any> = new EventEmitter<any>()
  @Input() set patchData(value: any){
    this.patchValue(value);
  }
  form: FormGroup;

  constructor(fb: FormBuilder) {
    this.form = fb.group({editor: [null]});
  }

  ngOnInit() {
    this.form
      .controls
      .editor
      .valueChanges.pipe(
        debounceTime(400),
        distinctUntilChanged()
      )
      .subscribe(data => {
        this.onChangeEditorData.emit(data);
      });
  }
  ngAfterViewInit(){
    this.patchValue(this.patchData)
  }

  //events starts
  setFocus($event) {
    $event.focus();
  }

  patchValue(data: any) {
    this.form.controls['editor'].patchValue(data)
  }

  logChange($event: any) {
    //your code here
  }

  logSelection($event: any) {
    //your code here
  }


}
