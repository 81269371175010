<ul>
  <li class="i18n-dropdown dropdown nav-item" display="dynamic" ngbDropdown>
    <a class="nav-link d-flex align-items-center dropdown-toggle dropdown-language" id="dropdown-flag"
       href="javascript:" data-toggle="dropdown" ngbDropdownToggle>
      <img class="langimg selected-flag" [src]="iPath + selectedLanguageFlag" alt="flag"/>
    </a>
    <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right text-left" aria-labelledby="dropdown-flag">
      <div class="block-item">
        <a *ngFor="let lang of languages"
           class="dropdown-item"
           data-language="{{lang.value}}"
           (click)="changeLanguage(lang.value)">
          <img class="langimg mr-2" [src]="iPath + lang.icon" alt="flag"/>
          <span class="font-small-3">{{lang.key}}</span>
        </a>
      </div>
    </div>
  </li>
</ul>
