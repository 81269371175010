import { Component, OnInit, Injector, Input, ViewChild, ElementRef, Output, EventEmitter, HostBinding, ChangeDetectorRef, forwardRef, NgZone } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { observable } from 'core-js/fn/symbol';
import { environment } from 'environments/environment.prod';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { CropperComponent } from '../cropper/cropper.component';


@Component({
  selector: 'app-multi-image-select',
  templateUrl: './multi-image-select.component.html',
  styleUrls: ['./multi-image-select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => MultiImageSelectComponent),
    multi: true
  }]
})
export class MultiImageSelectComponent implements ControlValueAccessor  {
  defautImage = 'assets/img/no-image.png';
  images = [];

  @ViewChild('swiper') swiper: ElementRef;
  public swiperMultipleConfig: SwiperConfigInterface = {
    slidesPerView: 5,
    spaceBetween: 10,
    pagination: {
      el: '.swiper-pagination',
      clickable: false,
    },
  };



  private onChange: Function;
  private onTouched: Function;

  constructor(private ref: ChangeDetectorRef,private modalService: NgbModal){
    this.onChange = (_: any) => {};
    this.onTouched = () => {};
  }

  deleteImg(img){
    this.images.splice(this.images.indexOf(img), 1);
    this.onChange(this.images);
  }

  writeValue(obj: any): void {
    if(obj == null){
      this.images = [];
    }
    else {
      for(let i = 0; i < obj.length; i++){
        if(obj[i][0] == '/'){
          this.images.push(obj[i]);
        }
        else{
          this.images = obj;
        }

      }

    }
    this.ref.detectChanges();
    this.swiper.nativeElement.swiper.update();

  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }



  process(event){
    const modalRef = this.modalService.open(CropperComponent, {backdrop: 'static', keyboard: false});
    modalRef.componentInstance.imageChangedEvent =  event;

    modalRef.result.then((base64) => {
      if(base64){
        this.images.push(base64);
        this.onChange(this.images);
        this.ref.detectChanges();
        this.swiper.nativeElement.swiper.update();
      }
      event.target.value = null;  
    });
  }

  reset() {
    this.images = [];
    this.onChange(null);
  }

  getBase64(file, callback) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      callback(reader.result);
    };
    reader.onerror = function (error) {
      callback(null);
    };
 }

//  imageChangedEvent: any = '';
//  croppedImage: any = '';

//  fileChangeEvent(event: any): void {
//      this.imageChangedEvent = event;
//  }
//  imageCropped(event: ImageCroppedEvent) {
//      this.croppedImage = event.base64;
//  }
//  imageLoaded() {
//      // show cropper
//  }
//  cropperReady() {
//      // cropper ready
//  }
//  loadImageFailed() {
//      // show message
//  }
}
