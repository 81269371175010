import { DatePipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "formatted",
})
export class FormattedDatePipe implements PipeTransform {
  constructor(public datepipe: DatePipe) {}

  transform(date: Date): any {
    if (date == null) {
      return null;
    }

    // let dateObj = new Date(date);



    let dateObj =  new DatePipe('en-us').transform(new Date(date + 'Z').toISOString(),'yyyy/MM/dd HH:mm')


    return this.datepipe.transform(dateObj, "dd-MM-yyyy HH:mm");
  }
}
