
<!-- Cropper starts -->
<section id="tours">
  <button class="close-modal" (click)="closeModal()">
    <i class="ft-x font-medium-3"></i>
  </button>
  <div class="row text-left">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h4>Измените размер изображения</h4>
        </div>
        <div class="card-body">

          <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="aspectRatio"
            format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
            (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()" class="float-left mr-4 img-fluid"></image-cropper>

          <br>




        </div>
      </div>
      <div class="d-flex flex-row-reverse m-3">
        <button class="btn btn-sm btn-primary ml-0 w-25" (click)="close()">Выбрать</button>
      </div>
    </div>
  </div>
  <!-- Cropper Ends -->

</section>
