import { Component, ViewChild } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ImageCroppedEvent } from "ngx-image-cropper";

@Component({
  selector: "app-cropper",
  templateUrl: "./cropper.component.html",
  styleUrls: ["./cropper.component.scss"],
})
export class CropperComponent {

  constructor(public activeModal: NgbActiveModal){

  }

  imageChangedEvent: any = "";
  croppedImage: any = "";
  aspectRatio: number = 4 / 4;

  closeModal(){
    this.activeModal.close(null);
  }
  close(){
    this.activeModal.close(this.croppedImage);
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }
}
