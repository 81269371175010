import { Component, OnInit, Injector, Input, ViewChild, ElementRef, Output, EventEmitter, HostBinding, ViewContainerRef } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { GridFiltersComponent } from '../../grid-filters.component';


@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent  {
  @HostBinding('class') class = 'col-12 col-sm-6 col-lg-3';
     
  name: any;
  valueForBinding: any;
    value: any;
    minDate = {} as any;
    maxDate={} as any;
    onChange(data) {
      this.value = data.year + '/' + data.month + '/' + data.day;      
    }
}
