<div class="row">
  <div class="col-12">
    <div class="content-header">
      <button class="backButton" backButton>
        <img src="assets/img/ep_arrow-right-bold.png" alt="" width="24" height="24">
      </button>
      <ng-content></ng-content>
    </div>
  </div>
</div>
