import { Component, OnInit, Injector, Input, ViewChild, ElementRef, Output, EventEmitter, HostBinding } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';


@Component({
  selector: 'app-multiselect-dropdown',
  templateUrl: './multiselect-dropdown.component.html',
  styleUrls: ['./multiselect-dropdown.component.scss']
})
export class MultiselectDropDownComponent  {
  @HostBinding('class') class = 'col-12 col-sm-6 col-lg-3';
  
  name: any;
  value: any;
  source: any;
}
