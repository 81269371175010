import {
  Component,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  ComponentFactoryResolver,
  ViewContainerRef,
  HostListener,
} from '@angular/core';
import { DropDownComponent } from './controls/dropdown/dropdown.component';
import { InputComponent } from './controls/input/input.component';
import { DatePickerComponent } from './controls/date-picker/date-picker.component';
import { CheckboxComponent } from './controls/checkbox/checkbox.component';
import { MultiselectDropDownComponent } from './controls/multiselect-dropdown/multiselect-dropdown.component';
import { DatePickerFromToComponent } from './controls/date-picker-from-to/date-picker-from-to.component';
import { AutocompleteDropdownComponent } from './controls/autocomplete-dropdown/autocomplete-dropdown.component';
import { MultiselectFilterComponent } from './controls/multiselect-filter/multiselect-filter.component';

@Component({
  selector: 'app-grid-filters',
  templateUrl: './grid-filters.component.html',
  styleUrls: ['./grid-filters.component.scss'],
})
export class GridFiltersComponent {
  @ViewChild('container', { read: ViewContainerRef })
  container: ViewContainerRef;

  inputFilters: any = [];
  outputFilter = {} as any;
  @Input() hasSearchBtn: boolean = false;
  @Input() redirectById: boolean;
  @Output() onAcceptEvent = new EventEmitter<string>();
  @Output() onResetEvent = new EventEmitter<string>();
  @Output() onRedirectEvent = new EventEmitter<string>();
  clearCompany = null;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
  ) {}

  async onAccept() {
    for (const input of this.inputFilters) {
      if (this.redirectById && this.outputFilter?.id) {
        this.onRedirectEvent.emit(this.outputFilter.id);
        return
      }
      if (input.type === 'datepickerFromTo') {
        this.outputFilter[input.valueFrom] =
          input.componentFactory.instance.valueFrom;
        this.outputFilter[input.valueTo] =
          input.componentFactory.instance.valueTo;
      } else {
        this.outputFilter[input.prop] = input.componentFactory.instance.value;
      }
      if (input.type === 'autocoplete-dropdown') {
        this.clearCompany = input.componentFactory.instance.value;
        this.outputFilter[input.prop] = input.componentFactory.instance.value?.id;
      }
    }
    for (const propName in this.outputFilter) {
      if (
        this.outputFilter[propName] == null ||
        this.outputFilter[propName] === -1
      ) {
        delete this.outputFilter[propName];
      }
    }

    this.onAcceptEvent.emit(this.outputFilter);
  }

  onReset() {
    this.resetControls();
  }

  resetControls() {
    this.inputFilters.forEach((input, index) => {
      // if (input.type == "dropdownBind") {
      //    input.componentFactory.instance.value =
      // }

      if (input.type == "datepickerFromTo") {
        input.componentFactory.instance.valueTo = null;
        input.componentFactory.instance.valueFrom = null;
        input.componentFactory.instance.minDateTo = {};
        input.componentFactory.instance.maxDateTo = {} as any;
        input.componentFactory.instance.minDateFrom = {} as any;
        input.componentFactory.instance.maxDateFrom = {} as any;
        // this.datePipe.transform(new Date(), 'shortDate');
        input.componentFactory.instance.valueForBindingTo = {};
        input.componentFactory.instance.valueForBindingFrom = {};

        // this.ref.detectChanges();
      } else if (input.type == "datepicker") {
        input.componentFactory.instance.valueForBinding = {};
        input.componentFactory.instance.value = null;
      } else {
        if (input.componentFactory.instance.defaultValue != null) {
          input.componentFactory.instance.value =
            input.componentFactory.instance.defaultValue;
        } else {
          input.componentFactory.instance.value = null;
        }
      }
    });
    for (const propName in this.outputFilter) {
      if (
        this.outputFilter[propName] == null ||
        this.outputFilter[propName] == -1
      ) {
        delete this.outputFilter[propName];
      }

      if (
        propName == "companyId" ||
        propName == "toIds" ||
        propName == "fromIds"
      ) {
        delete this.outputFilter[propName];
      }
    }

    this.onResetEvent.emit(null);
  }

  deleteControls() {
    this.container.clear();
    this.inputFilters = [];
    this.outputFilter = {};
    this.generateControls();
  }

  generateControls() {
    this.inputFilters = this.inputFilters.filter(
      (r) => r.isShow == null || r.isShow
    );
    this.inputFilters.forEach((input, index) => {
      if (input.type == "input") {
        const resolver =
          this.componentFactoryResolver.resolveComponentFactory(InputComponent);
        input.componentFactory = this.container.createComponent(resolver);
        input.componentFactory.instance.name = input.name;
        input.componentFactory.instance.type = input.inputType;
        input.componentFactory.instance.placaholder = input.placeholder ? input.placeholder : '';
      } else if (input.type == "autocoplete-dropdown") {
        const resolver = this.componentFactoryResolver.resolveComponentFactory(
          AutocompleteDropdownComponent
        );
        input.componentFactory = this.container.createComponent(resolver);
        input.componentFactory.instance.propName = input.propName;
        input.componentFactory.instance.name = input.name;
        input.componentFactory.instance.source = input.source;
        input.componentFactory.instance.filter = input.filter;
        input.componentFactory.instance.multiple = input.multiple;
        input.componentFactory.instance.value = input?.value;
        input.componentFactory.instance.isShowIds = input?.isShowIds == false ? input.isShowIds : true;
      } else if (input.type == "datepicker") {
        const resolver =
          this.componentFactoryResolver.resolveComponentFactory(
            DatePickerComponent
          );
        input.componentFactory = this.container.createComponent(resolver);
        input.componentFactory.instance.name = input.name;
        input.componentFactory.instance.valueForBinding = input.source;
      } else if (input.type == "datepickerFromTo") {
        const resolver = this.componentFactoryResolver.resolveComponentFactory(
          DatePickerFromToComponent
        );
        input.componentFactory = this.container.createComponent(resolver);
        input.componentFactory.instance.nameTo = input.nameTo;
        input.componentFactory.instance.nameFrom = input.nameFrom;
        input.componentFactory.instance.valueTo = input.sourceTo;
        input.componentFactory.instance.valueFrom = input.sourceFrom;

        if (input.type1) {
          input.componentFactory.instance.valueForBindingTo = {};
          input.componentFactory.instance.valueForBindingFrom = {};
        } else {
          input.componentFactory.instance.valueForBindingTo = {};
          input.componentFactory.instance.valueForBindingFrom = {};
        }
      } else if (input.type == "checkbox") {
        const resolver =
          this.componentFactoryResolver.resolveComponentFactory(
            CheckboxComponent
          );
        input.componentFactory = this.container.createComponent(resolver);
        input.componentFactory.instance.isDisabled = input.isDisabled;
        input.componentFactory.instance.name = input.name;
        input.componentFactory.instance.source = input.source;
        input.componentFactory.instance.value = input?.value;
      } else if (input.type == "multiselect-dropdown") {
        const resolver = this.componentFactoryResolver.resolveComponentFactory(
          MultiselectDropDownComponent
        );
        input.componentFactory = this.container.createComponent(resolver);
        input.componentFactory.instance.name = input.name;
        input.componentFactory.instance.source = input.source;
      } else if (input.type === "multiselect-filter") {
        const resolver = this.componentFactoryResolver.resolveComponentFactory(
          MultiselectFilterComponent
        );
        input.componentFactory = this.container.createComponent(resolver);
        input.componentFactory.instance.name = input.name;
        input.componentFactory.instance.source = input.source;
      } else if (input.type == "dropdown") {
        const resolver =
          this.componentFactoryResolver.resolveComponentFactory(
            DropDownComponent
          );
        input.componentFactory = this.container.createComponent(resolver);
        input.componentFactory.instance.name = input.name;
        input.componentFactory.instance.source = input.source;
        input.componentFactory.instance.multiple = input.multiple;
        input.componentFactory.instance.value = input.value;
        input.componentFactory.instance.defaultValue = input.defaultValue;
      }
    });
  }
}
