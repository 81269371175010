import {Injectable} from '@angular/core';
import {StorageService, StorageValue} from './storage.service';
import * as signalR from '@microsoft/signalr';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SignalrService {
  hubUrl: string;
  connection: any;

  parentChatHub: any;
  workChatHub: any;
  notificationHub: any;

  parentChatHubUrl: any;
  workChatHubUrl: any;
  notificationHubUrl: any;

  constructor() {
    this.hubUrl = `${environment.signalrUrl}/signalr/parentchat`;

    this.parentChatHubUrl = `${environment.signalrUrl}/signalr/parentchat`;
    this.workChatHubUrl = `${environment.signalrUrl}/signalr/workchat`;
    this.notificationHubUrl = `${environment.signalrUrl}/signalr/notification`;
  }

  public async initiateSignalrConnection(): Promise<void> {
    try {
      Object.defineProperty(WebSocket, 'OPEN', {value: 1});
      const user = StorageService.get(StorageValue.User);
      if (!this.parentChatHub || this.parentChatHub.stat === signalR.HubConnectionState.Disconnected) {
        this.parentChatHub = new signalR.HubConnectionBuilder()
          .configureLogging(signalR.LogLevel.Debug)
          .withUrl(this.parentChatHubUrl, {
              accessTokenFactory: () => {
                console.log('accessTokenFactory');
                return user.token;
              },
              transport: signalR.HttpTransportType.WebSockets
            }
          )
          .withAutomaticReconnect()
          .build();
      }
      if (!this.workChatHub || this.workChatHub.stat === signalR.HubConnectionState.Disconnected) {
        this.workChatHub = new signalR.HubConnectionBuilder()
          .configureLogging(signalR.LogLevel.Debug)
          .withUrl(this.workChatHubUrl, {
              accessTokenFactory: () => {
                console.log('accessTokenFactory');
                return user.token;
              },
              transport: signalR.HttpTransportType.WebSockets
            }
          )
          .withAutomaticReconnect()
          .build();
      }
      if (!this.notificationHub || this.notificationHub.stat === signalR.HubConnectionState.Disconnected) {
        this.notificationHub = new signalR.HubConnectionBuilder()
          .configureLogging(signalR.LogLevel.Debug)
          .withUrl(this.notificationHubUrl, {
              accessTokenFactory: () => {
                console.log('accessTokenFactory');
                return user.token;
              },
              transport: signalR.HttpTransportType.WebSockets
            }
          )
          .withAutomaticReconnect()
          .build();
      }
      console.log(`SignalR connection success! connectionId: ${this.connection.connectionId}`);
    } catch (error) {
      console.log(`SignalR connection error: ${error}`);
    }
  }

  public async sendMessage(data) {
    return this.workChatHub.invoke('SendMessage', data);
  }

  public async startParentConnection() {
    await this.parentChatHub.start()
      .then(() => {


      }).catch(() => {

      });
  }

  public async stopParentConnection() {
    await this.parentChatHub.stop()
  }

  public async startWorkConnection() {
    await this.workChatHub.start()
      .then(() => {


      }).catch(() => {

      });
  }

  public async stopWorkConnection() {
    await this.workChatHub.stop()
  }

  public async startNotificationConnection() {
    await this.notificationHub.start()
      .then(() => {


      }).catch(() => {

      });
  }

  public async stopNotificationConnection() {
    await this.notificationHub.stop()
  }
}
