<!--table starts-->
<section id="ng-tables">
  <div class="row text-left">
    <div class="col-12">
      <div class="card">
        <div class="card-content">
          <div class="card-body">

            <form>
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                  <tr>
                    <th *ngFor="let header of data.headers" scope="col">{{header.displayName | translate}}</th>
                    <th *ngIf="data.actions != null" scope="col">{{data.actions.displayName}}</th>
                    <th *ngFor="let column of data.extraColumns "></th>
                  </tr>

                  </thead>
                  <tbody>
                  <td *ngIf="total==0" class="empty-table-info" [attr.colspan]="data.headers.length">
                    {{'common.the_result_not_found' | translate}}
                  </td>
                  <tr *ngFor="let row of rows">
                    <ng-container *ngIf="!row?.isDeleted">
                      <td *ngFor="let header of data.headers; let i = index"
                          [attr.data-label]="header.displayName | translate"
                          [attr.data-empty]="!row[header.property] ? 'true' : null"
                          class="table-td">
                        <ng-container [ngSwitch]="data.headers[i]?.type">
                          <img class="data-img" *ngSwitchCase="'image'" [src]="mediaUrl + row[data.headers[i].property]"
                               onerror="this.src='assets/img/no-image.png';" alt="">
                          <ng-container *ngSwitchCase="null">
                            {{row | gridrowvalue : data.headers[i]?.property}}
                          </ng-container>
                          <ng-container *ngSwitchCase="'datetime'">
                            {{dateUtility.convertDate(row[data.headers[i].property], data.headers[i].property)}}
                            <!-- {{row[data.headers[i].property]}} -->
                          </ng-container>
                          <ng-container *ngSwitchCase="'boolean'">
                            {{(row | gridrowvalue : data.headers[i]?.property) == true ? 'Да' : 'Нет'}}
                          </ng-container>
                          <ng-container *ngSwitchCase="'link'">
                            <a href="{{row | gridrowvalue : data.headers[i]?.property}}">
                              {{row | gridrowvalue : data.headers[i]?.property}}
                            </a>
                          </ng-container>
                          <ng-container *ngSwitchCase="'percente'">
                            {{row | gridrowvalue : data.headers[i]?.property}}%
                          </ng-container>
                          <ng-container *ngSwitchCase="'html'">
                            {{row | gridrowvalue : data.headers[i]?.property | removeHtmlTags}}
                          </ng-container>
                          <ng-container *ngSwitchCase="'file'">
                              <span (click)="getFiles()">
                                <img src="assets/img/icons/clip.svg" alt="clip">
                                <span class="mr-1">{{row[data.headers[i].property]}}</span>
                                <span>файлов</span>
                              </span>
                          </ng-container>
                        </ng-container>

                      </td>
                      <td *ngFor="let column of data.extraColumns; let i = index">
                        <ng-container [ngSwitch]="data.extraColumns[i]?.type">
                          <div class="buttons-group">
                            <button [ngClass]="{'cancel-button table-button': row.isSelected,
                             'cancel-button': !row.isSelected}" [disabled]="row.isSelected" (click)="toggleSelection(row); $event.stopPropagation()"
                            >{{row.isSelected ? ('common.selected' | translate) : ('actions.choose' | translate)}}</button>
                            <button *ngIf="row.isSelected" [ngClass]="" class="cancel-button" (click)="clearSelection(row)"
                            >{{'actions.cancel' | translate}}
                            </button>
                          </div>
                        </ng-container>
                      </td>
                      <td *ngIf="data.actions != null" class="actions-td d-flex">
                        <a *ngIf="getAction(actionType.Edit) != null" class="success p-0 mr-2"
                           (click)="setSourceAndNavigate(actionType.Edit, row)">
                          <img src="assets/img/icons/pencil.svg" alt="pencil">
                        </a>
                        <a *ngIf="getAction(actionType.Delete) != null && !row.isDeleted" class="danger p-0"
                           (click)="delete(row)">
                          <img src="assets/img/icons/basket.svg" alt="basket">
                        </a>
                        <a *ngIf="getAction(actionType.Duplicate) != null" class="danger p-0" (click)="dublicate(row)">
                          <i class="ft-x font-medium-3"></i>
                        </a>
                      </td>
                    </ng-container>
                  </tr>
                  </tbody>
                </table>
              </div>

              <div *ngIf="pagination" class="d-flex p-2">

                <ngb-pagination (pageChange)="loadPage($event)" [collectionSize]="total" [(page)]="currentPage"
                                [maxSize]="4" [rotate]="true"
                                [ellipses]="true" [boundaryLinks]="true" [pageSize]="pageSize">
                </ngb-pagination>
                <div class="go-to-page d-flex ml-2">
                  <!-- <input type="number" class="form-control" placeholder="#" [(ngModel)]="pageInputValue" [ngModelOptions]="{standalone: true}"> -->
                  <!-- <button type="button" class="move btn btn-primary" (click)="goToPage()">Перейти</button> -->
                </div>
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--table ends-->
