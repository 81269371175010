import { Injectable } from "@angular/core";

export enum StorageValue {
  User = "Okid_User",
  CurrentClientCompanyInfo = "Current_Client_Company_Info"
}

export enum RoleEnum {
  SuperAdmin = 1,
  Manager = 2,
  Araqich = 3,
  Merchendaizer = 4,
  StockManager = 5,
}

export enum MessageSenderTypeEnum {
  Parent = 1,
  Manager = 2,
  Director = 3,
}


@Injectable({
  providedIn: "root",
})
export class StorageService {
  static get(key: StorageValue): any {
    try {
      let value = localStorage.getItem(key);
      if (value != null) {
        return JSON.parse(value);
      }
      return null;
    } catch (err) {
      return null;
    }
  }

  static set(key: StorageValue, value: any) {
    return localStorage.setItem(key, JSON.stringify(value));
  }

  static delete(key: StorageValue) {
    return localStorage.removeItem(key);
  }
}
