import { Component, OnInit, Injector, Input, ViewChild, ElementRef, Output, EventEmitter, HostBinding } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';


@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent  {
  @HostBinding('class') @Input() class = 'col-12 col-sm-4 col-lg-2';

  @Input() name: any;
  @Input() value: any;
  @Input() checkboxFor = true

  openDate() {
    (document.getElementById(this.name) as any).toggle();
  }
}
