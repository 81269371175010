import { environment } from './../../../environments/environment';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'urlImage'
})
export class UrlImagePipe implements PipeTransform {
  transform(image: string): any {
    if(image[0] == '/'){
      return environment.imageUrl + image;
    }
    return image;
  }
}
