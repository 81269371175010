import { Injectable, Pipe, PipeTransform } from "@angular/core";
import { StaticTextsService } from "../services/static-texts.service";
@Pipe({
  name: "translation",
  pure: true,
})
@Injectable({ providedIn: "root" })
export class TranslationPipe implements PipeTransform {
  constructor(private StaticTexts: StaticTextsService) {}

  transform(key: string): any {
    if (
      this.StaticTexts.staticTexts &&
      this.StaticTexts.staticTexts.length &&
      key
    ) {
      if (this.StaticTexts.staticTexts.find((x) => x.key === key)) {
        return this.StaticTexts.staticTexts.find((x) => x.key === key)?.name;
      } else {
        return key;
      }
    } else {
      return key;
    }
  }
}
