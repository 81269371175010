<div class="form-group">
  <label for="users-list-verified">{{name}}</label>

    <ng-select  [(ngModel)]="value" [multiple]="multiple" [ngModelOptions]="{standalone: true}" >
      <!--<ng-option value="-1">-</ng-option>-->
      <ng-option [value]="st.id" *ngFor="let st of source">{{st.name}}</ng-option>
    </ng-select>

</div>

