<ng-container>
  <input class="form-control phone-number-input" [id]="name"
         [formControl]="control"
         [type]='type'
         [ngClass]="class"
         placeholder="{{placeholder}}"
         [pattern]="pattern">
</ng-container>



