export enum UserTypeEnum {
  Director = 1,
  Manager = 2,
  Monitoring = 3
}

export type UserType = {id: number, name: string}

export const Staff: UserType[] = [
  {id: UserTypeEnum.Manager, name: 'Manager'},
  {id: UserTypeEnum.Monitoring, name: 'Monitoring'},
]
