import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Languages } from "../../enums/languages.enum";

@Component({
  selector: "app-lang-selector",
  templateUrl: "./lang-selector.component.html",
  styleUrls: ["./lang-selector.component.scss"]
})
export class LangSelectorComponent implements OnInit {
  @Input() isParentUsed: boolean = false
  iPath = './assets/img/flags/';
  currentLang = "en";
  selectedLanguageText: string = "English";
  selectedLanguageFlag: string = `en.svg`;
  localStorageLang: string;
  languages = Languages;
  browserLang = this.translate.getBrowserLang();

  constructor(public translate: TranslateService) {}

  ngOnInit() {
    this.localStorageLang = localStorage.getItem('localStorageLang');
    if (this.browserLang && !this.isParentUsed) {
      this.selectLang(this.browserLang);
      console.log(this.browserLang);
      const isBrowserLangEqual = this.languages.some(lang => lang.value == this.browserLang);
      this.translate.use(isBrowserLangEqual && this.browserLang);
    }
    if (this.localStorageLang && !this.isParentUsed) {
      this.selectLang(this.localStorageLang);
      this.translate.use(this.localStorageLang);
    }
    else if (!this.browserLang && !this.localStorageLang) {
      this.translate.use(this.currentLang);
      this.selectLang(this.currentLang);
    }
  }

  changeLanguage(language: string) {
    this.currentLang = language;
    if (!this.isParentUsed) {
      localStorage.setItem('localStorageLang', this.currentLang);
    }
    this.selectLang(this.currentLang);
    this.translate.use(this.currentLang);
  }

  selectLang(language: string) {
    const selectLang = this.languages.find(lang => lang.value == language);
    this.selectedLanguageText = selectLang.key;
    this.selectedLanguageFlag = selectLang.icon;
  }
}
