import {Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-modal-wrapper',
  templateUrl: './modal-wrapper.component.html',
  styleUrls: ['./modal-wrapper.component.scss']
})
export class ModalWrapperComponent implements OnChanges {
  @Input() isOpen: boolean = false;
  @Input() title: string = '';
  @Output() closeModalEvent = new EventEmitter<boolean>();


  ngOnChanges(changes: SimpleChanges) {
  }

  closeModal() {
    this.isOpen = false
    this.closeModalEvent.emit(this.isOpen);
  }
}
