export function hasError(response): boolean {
  return response.hasError || response.HasError;
}

export function getErrorText(response): string {
  const toastNoticesKey: string = 'toast_notices.';
  return (response.errors || response.Errors)
    .map(e => e.key ? toastNoticesKey + e.key : e.description || e.Description)
    .join(', ');
}
