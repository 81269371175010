import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'gridrowvalue'
})
export class GridRowValuePipe implements PipeTransform {

  transform(row, prop: string): any {
    if (prop == null) {
      return null;
    }

    let props = prop.split('.');
    let value = row;
    props.forEach(e => {
      if(value != null){
        value = value[e];
      }
    });
    return value;
  }
}
