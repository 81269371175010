import { Component, OnInit, Injector, Input, ViewChild, ElementRef, Output, EventEmitter, HostBinding } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';


@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent  {
  @HostBinding('class') class = 'col-12 col-sm-6 col-lg-3';
  name: any;
  value: any;
  type = 'text';
  placaholder: string = '';
}
