<div class="modal" tabindex="-1" role="dialog" [ngClass]="{'show': isOpen}">
  <div class="modal-dialog" role="document" (click)="$event.stopPropagation()">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ title | translate }}</h5>
        <button type="button" class="close" (click)="closeModal()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
