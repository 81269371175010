import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
// @ts-ignore
export class DataTransferService {
  private dataToStore: any = {}

  constructor() {
  }

  // @ts-ignore
  get data(): any {
    return this.dataToStore
  }

  // @ts-ignore
  set data(value: any) {
    this.dataToStore = value
  }

}
