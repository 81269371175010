<div class="form-group row">
    <div class="controls col-sm-6 col-12">
        <label for="users-edit-country">{{nameFrom}}</label>
        <div class="input-group">
            <input class="form-control"
                   [readonly] = "true"
                   [minDate]="minDateFrom"
                   [maxDate]="maxDateFrom"
                   (ngModelChange)="onChangeFrom($event)"
                   placeholder="yyyy-mm-dd"
                   name="dp"
                   [(ngModel)]='valueForBindingFrom' ngbDatepicker
                   #d2="ngbDatepicker">
            <div class="input-group-append">
                <div class="input-group-text" (click)="d2.toggle()">
                    <i class="fa fa-calendar" style="cursor: pointer;"></i>
                </div>
            </div>
        </div>
    </div>
    <div class="controls col-sm-6 col-12">
        <label for="users-edit-country">{{nameTo}}</label>
        <div class="input-group">
            <input class="form-control"
                   [readonly] = "true"
                   [minDate]="minDateTo"
                   [maxDate]="maxDateTo"
                   (ngModelChange)="onChangeTo($event)"
                   placeholder="yyyy-mm-dd"
                   name="dp"
                   [(ngModel)]='valueForBindingTo'
                   ngbDatepicker
                   #d1="ngbDatepicker">
            <div class="input-group-append">
                <div class="input-group-text" (click)="d1.toggle()">
                    <i class="fa fa-calendar" style="cursor: pointer;"></i>
                </div>
            </div>
        </div>
    </div>
</div>

