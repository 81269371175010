import { AbstractControl, FormGroup, ValidatorFn } from '@angular/forms';

export class Validator {

  public fioPattern = /^(?!\s)(?!-)[A-Za-zА-Яа-яЁё -]*(?<!-)$/;
  public emailPattern = '[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'
  public phonePattern = '^\\+[0-9]{10,20}$'

  public password(formGroup: FormGroup) {
    const { value: password } = formGroup.get('password');
    const { value: confirmPassword } = formGroup.get('confPassword');
    formGroup.controls.confPassword?.setErrors(password === confirmPassword ? null : { passwordNotMatch: true });
    formGroup.controls.confPassword?.setErrors(confirmPassword?.length > 7 ? null : { passwordLength: true });
    return password === confirmPassword ? null : { passwordNotMatch: true };
  }

  public noWhitespaceValidator(length): ValidatorFn {
    return (control: AbstractControl) => {
      const value = typeof control.value === 'number' ? control.value.toString() : control.value;
      const isWhitespace = (value || '').trim().length >= length;
      return isWhitespace ? null : { 'whitespace': true };
    };
  }
  public isNumericValidator(): ValidatorFn {
    return (control: AbstractControl) => {
      if (isNaN(control.value)) {
        return {'isNumeric': true}
      }
      return null;
    };
  }

  public endpointValidator(): ValidatorFn {
    return (control: AbstractControl) => {
      const value = control.value;
      if (!value) {
        return null;
      }
      const regex = /^https?:\/\/(\d{1,3}\.){3}\d{1,3}:\d{1,5}\/$/;
      return regex.test(value) ? null : { 'invalidEndpoint': true };
    };
  }
}
