import {Directive, HostListener} from '@angular/core';
import {NgControl} from '@angular/forms';

@Directive({
  selector: '[appDateDir]'
})
export class DateDirective {
  private maxDateLength = 10;

  constructor(public ngControl: NgControl) {
  }

  @HostListener('ngModelChange', ['$event'])
  onModelChange(event) {
    if (event) {
      let result = event;

      if (event.length > this.maxDateLength) {
        result = event.slice(-this.maxDateLength);
      }

      result = this.getFormatedDate(result);

      this.ngControl.valueAccessor.writeValue(result);
    }
  }

  getFormatedDate(date: string) {
    const currentDate = new Date();
    const inputDate = new Date(date);
    const correctiongJsMonthIndex = 1;

    if (inputDate > currentDate) {
      let day: string | number = currentDate.getDate();
      let month: string | number = currentDate.getMonth() + correctiongJsMonthIndex;
      const year = currentDate.getFullYear();

      const numberMonth = 10;
      const numberDay = 10;

      month = month < numberMonth ? `0${month}` : month;
      day = day < numberDay ? `0${day}` : day;

      return `${year}-${month}-${day}`;
    }

    return date;
  }

}
