<div class="card">
  <div class="card-content">
    <div class="card-body">

      <div class="row">
        <ng-container   #container></ng-container>

      </div>
      <div class="row">
       <div class="button-group-items">
        <button  (click)="onAccept()" class="btn btn-info">{{"actions.apply" | translate}}</button>
        <button (click)="onReset()" class="btn btn-primary">{{"actions.reset" | translate}}</button>
       </div>

      </div>

    </div>
  </div>
</div>
