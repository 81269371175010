import { Injectable } from '@angular/core';
import { environment } from 'environments/environment.prod';
import { IStaticText } from '../models/base.model';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class StaticTextsService {
  constructor(private http: HttpClient) {}
  private authEndpoints = {
    statictext: 'static-text/get-all',
  };
  private apiUrl: string = environment.url;
  public staticTexts: IStaticText[] = [{ key: 'key', name: 'text' }];

  public async getStaticTexts() {
    return await this.http
      .get(this.apiUrl + this.authEndpoints.statictext)
      .toPromise();
  }
}
