import {Component, Input, OnChanges} from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import {Validator} from "../../../validators/validators";

@Component({
  selector: 'app-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss'],
})
export class PhoneInputComponent implements OnChanges {
  @Input() name = 'phone';
  @Input() type = 'text';
  @Input() control: AbstractControl = new FormControl();
  @Input() disabled = false;
  @Input() class = null;
  validator = new Validator();
  public pattern = this.validator.phonePattern
  public placeholder = '+XXXX...';

  ngOnChanges() {
    if (this.disabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }
}
