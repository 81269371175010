export enum FiscalRegistrarEnum {
  New = 0,
  ReadyToFiscal = 1,
  FiscalComplete = 2,
  FiscalError = 3
}

export type FiscalRegistrarStatus = {key: number, name: string};

export const FiscalRegistrarStatuses: FiscalRegistrarStatus[] = [
  {key: FiscalRegistrarEnum.New, name: 'fiscalization.fiscalization_status_new'},
  {key: FiscalRegistrarEnum.ReadyToFiscal, name: 'fiscalization.ready_for_fiscalization'},
  {key: FiscalRegistrarEnum.FiscalComplete, name: 'fiscalization.fiscalization_completed'},
  {key: FiscalRegistrarEnum.FiscalError, name: 'fiscalization.fiscalization_error'}
];
