import {
  Component,
  OnInit,
  Injector,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  HostBinding,
  ChangeDetectorRef,
  forwardRef,
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ProxyService} from 'app/shared/services/proxy.service';
import {observable} from 'core-js/fn/symbol';
import {fromEvent} from 'rxjs';
import {debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {CropperComponent} from '../cropper/cropper.component';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-single-dropdown',
  templateUrl: './single-dropdown.component.html',
  styleUrls: ['./single-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SingleDropdownComponent),
      multi: true,
    },
  ],
})
export class SingleDropdownComponent implements ControlValueAccessor {
  @ViewChild('searchinput') input: any;
  @Input() items = [];
  @Input() propName: any;
  @Input() propDisplay: string;
  @Input() readonly: any;
  value = null;
  @Input() source: any;
  @Input() filter: any;
  @Input() sourceParametrs: any;
  @Input() multiple: boolean;
  @Input() hasColor: string;
  @Input() disableClear: boolean;
  @Output() changeEvent = new EventEmitter<string>();
  @Input() isInvalid: boolean = false;

  private onChange: Function;
  private onTouched: Function;

  constructor(private ref: ChangeDetectorRef, private spinner: NgxSpinnerService, private proxy: ProxyService) {
    this.onChange = (_: any) => {
    };
    this.onTouched = () => {
    };
  }

  onChangeItem() {
    this.onChange(this.value);
    this.changeEvent.emit(this.items.find(i => i[this.propName] == this.value));
  }

  writeValue(obj: any): void {
    if (obj == null) {
      this.value = null;
    } else {
      this.value = Array.isArray(obj) && !this.multiple ? obj[0] : obj;
    }
    this.ref.detectChanges();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  ngAfterViewInit() {
    fromEvent(this.input.element, 'input')
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap((text) => {
          this.getData(this.input.searchTerm);
        })
      )
      .subscribe();
    this.getData('');
  }

  getData(event) {
    if (this.source == null) {
      return;
    }
    let res = {};
    if (this.filter) {
      res = {
        take: 20,
        skip: 1,
      };
    }
    if (event != null && event.trim() != '') {
      res[this.propName] = event;
    }
    let src = this.source;
    if (typeof this.source === 'string') {
      if (this.source.split('?').length > 1) {
        res = {
          ...res,
          ...JSON.parse(
            '{"' +
            decodeURI(this.source.split('?')[1])
              .replace(/"/g, '\\"')
              .replace(/&/g, '","')
              .replace(/=/g, '":"') +
            '"}'
          ),
        };
        src = this.source.split('?')[0];
      }

      this.proxy.get(src, res, null).subscribe((data) => {
        this.items = (data as any).data.entities;
        this.ref.detectChanges();
      });
    }
    if (this.source instanceof Array) {
      this.items = this.source;
      this.spinner.hide();
      this.ref.detectChanges();
    }
  }
}

