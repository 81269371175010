import { Injectable } from '@angular/core'
import { Location } from '@angular/common'
import { Router, NavigationEnd } from '@angular/router'
import {NavigateUrls} from '../navigate-urls/navigate-urls';
import {from} from 'rxjs';

@Injectable({
  providedIn: "root",
})
export class NavigationService {
  private history: string[] = []

  constructor(private router: Router, private location: Location) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects)
        if(event.urlAfterRedirects == '/login') {
          this.history = [];
        }
      }
    })
  }

  back(): void {
    const lastPageUrl = this.history.pop();
    if (lastPageUrl !== NavigateUrls.Login) {
      history.back();
      this.history.pop();
    }

  }
}
