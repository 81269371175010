import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { SafeUrl } from '@angular/platform-browser';
import { QRCodeComponent } from 'angularx-qrcode';

@Component({
  selector: 'app-qr-modal',
  templateUrl: './qr-modal.component.html',
  styleUrls: ['./qr-modal.component.scss']
})
export class QrModalComponent implements OnInit {

  @ViewChild(QRCodeComponent) qrCodeComponent: QRCodeComponent;
  @Input() headerText: string;
  @Input() footerText: string;
  @Input() id: string;
  public qrImage;
  public defaultId = 'modal-2'

  constructor(
    public modalService: ModalService,
  ) {
  }

  ngOnInit(): void {}

  open(img: any) {
    this.qrImage = img;
    if (this.id)
      this.modalService.open(this.id);
    else
      this.modalService.open(this.defaultId);
  }

    onPrint() {
    window.print()
  }
}
