
<div class=" col" >
  <img [src]="image | urlImage" alt="user edit avatar" height="129" width="129" class="m-2" [ngClass]="{'hasMultiOnPage': hasMultiOnPage, 'selected': hasMultiOnPage && selected}">
  <!--<button type="button" class="close" style="position: absolute; top: 8%; left: 33%;">
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.72032 3.72015C3.86094 3.5797 4.05157 3.50081 4.25032 3.50081C4.44907 3.50081 4.63969 3.5797 4.78032 3.72015L8.00032 6.94015L11.2203 3.72015C11.289 3.64647 11.3718 3.58736 11.4638 3.54637C11.5558 3.50538 11.6551 3.48334 11.7558 3.48156C11.8565 3.47979 11.9565 3.49831 12.0499 3.53603C12.1433 3.57375 12.2281 3.6299 12.2994 3.70112C12.3706 3.77233 12.4267 3.85717 12.4644 3.95056C12.5022 4.04394 12.5207 4.14397 12.5189 4.24468C12.5171 4.34538 12.4951 4.44469 12.4541 4.53669C12.4131 4.62869 12.354 4.71149 12.2803 4.78015L9.06032 8.00015L12.2803 11.2202C12.354 11.2888 12.4131 11.3716 12.4541 11.4636C12.4951 11.5556 12.5171 11.6549 12.5189 11.7556C12.5207 11.8563 12.5022 11.9564 12.4644 12.0498C12.4267 12.1431 12.3706 12.228 12.2994 12.2992C12.2281 12.3704 12.1433 12.4266 12.0499 12.4643C11.9565 12.502 11.8565 12.5205 11.7558 12.5187C11.6551 12.517 11.5558 12.4949 11.4638 12.4539C11.3718 12.4129 11.289 12.3538 11.2203 12.2802L8.00032 9.06015L4.78032 12.2802C4.63814 12.4126 4.4501 12.4848 4.25579 12.4813C4.06149 12.4779 3.87611 12.3992 3.73869 12.2618C3.60128 12.1244 3.52257 11.939 3.51914 11.7447C3.51571 11.5504 3.58784 11.3623 3.72032 11.2202L6.94032 8.00015L3.72032 4.78015C3.57987 4.63953 3.50098 4.4489 3.50098 4.25015C3.50098 4.0514 3.57987 3.86078 3.72032 3.72015Z" fill="black"/>
    </svg>
  </button>-->

  <div class="media-body" style="width: 140px;">
    <div class="d-flex flex-sm-row flex-column justify-content-start px-0 mb-sm-3">
      <label class="custom-file d-flex flex-column">
        <span class="btn btn-sm btn-primary mb-1 mb-sm-0 ml-2 ">{{'actions.choose' | translate}}</span>

        <input type="file" hidden accept="image/*" (change)="process($event)">
        <button type="button" (click)="reset()"  class="btn btn-sm bg-light-secondary ml-sm-2 mt-2">{{'actions.reset' | translate}}</button>
     </label>

    </div>
  </div>
</div>
