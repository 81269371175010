<app-modal class="size-fit" [id]="id ? id : defaultId">
  <div class="doNotPrint"><h3>QR code</h3></div>
  <div *ngIf="headerText" class="doNotPrint">
    <h5 [innerHTML]="headerText | translate"></h5>
  </div>
  <div class="qr-container">
    <qrcode
      [qrdata]="qrImage"
      [width]="512"
      [elementType]="'img'"
      [errorCorrectionLevel]="'M'">
    </qrcode>
<!--    <img [src]='qrImage' alt="">-->
  </div>
  <div *ngIf="footerText" class="doNotPrint">
    <h5 [innerHTML]="footerText | translate"></h5>
  </div>
  <div class="doNotPrint">
    <button
      class="btn btn-info"
      (click)="onPrint()">
      {{'actions.print' | translate}}
    </button>
    <button
      class="btn btn-info"
      (click)="modalService.close()">
      {{'actions.close' | translate}}
    </button>
  </div>
</app-modal>


