import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OverlayModule } from '@angular/cdk/overlay';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

import { QuillModule } from 'ngx-quill';
import { ClickOutsideModule } from 'ng-click-outside';

import { AutocompleteModule } from './partial-components/autocomplete/autocomplete.module';
import { PipeModule } from 'app/shared/pipes/pipe.module';

//COMPONENTS
import { DataTableComponent } from './partial-components/data-table/data-table.component';
import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { VerticalMenuComponent } from './vertical-menu/vertical-menu.component';
import { CustomizerComponent } from './customizer/customizer.component';
import { NotificationSidebarComponent } from './notification-sidebar/notification-sidebar.component';
import { TopMenuAnchorToggleDirective } from './directives/topmenu-anchor-toggle.directive';

import { InputComponent } from './partial-components/grid-filters/controls/input/input.component';
import {
  AutocompleteDropdownComponent
} from './partial-components/grid-filters/controls/autocomplete-dropdown/autocomplete-dropdown.component';
import { CheckboxComponent } from './partial-components/grid-filters/controls/checkbox/checkbox.component';
import { DatePickerComponent } from './partial-components/grid-filters/controls/date-picker/date-picker.component';
import { DatePickerFromToComponent } from './partial-components/grid-filters/controls/date-picker-from-to/date-picker-from-to.component';
import { DropDownComponent } from './partial-components/grid-filters/controls/dropdown/dropdown.component';
import {
  MultiselectDropDownComponent
} from './partial-components/grid-filters/controls/multiselect-dropdown/multiselect-dropdown.component';
import { MultiselectFilterComponent } from './partial-components/grid-filters/controls/multiselect-filter/multiselect-filter.component';

//DIRECTIVES
import { ToggleFullscreenDirective } from './directives/toggle-fullscreen.directive';
import { SidebarLinkDirective } from './directives/sidebar-link.directive';
import { SidebarDropdownDirective } from './directives/sidebar-dropdown.directive';
import { SidebarAnchorToggleDirective } from './directives/sidebar-anchor-toggle.directive';
import { SidebarDirective } from './directives/sidebar.directive';
import { TopMenuDirective } from './directives/topmenu.directive';
import { TopMenuLinkDirective } from './directives/topmenu-link.directive';
import { TopMenuDropdownDirective } from './directives/topmenu-dropdown.directive';
import { GridFiltersComponent } from './partial-components/grid-filters/grid-filters.component';
import { ImageSelectComponent } from './partial-components/image-select/image-select.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { NgSelectModule } from '@ng-select/ng-select';
import { MultiImageSelectComponent } from './partial-components/multi-image-select/multi-image-select.component';
import { CropperComponent } from './partial-components/cropper/cropper.component';
import { SingleDropdownComponent } from './partial-components/single-dropdown/single-dropdown.component';
import { EditorComponent } from './partial-components/editor/editor.component';
import { BackButtonDirective } from './directives/BackButtonDirective.directive'
import { DataSelectionTableComponent } from './partial-components/data-selection-table/data-selection-table.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableModule } from '@angular/material/table';
import { ModalComponent } from './partial-components/base-modal/base-modal.component';
import {SvgEditComponent} from './svg-components/edit/edit.component';
import {SvgDeleteComponent} from './svg-components/delete/delete.component';
import {SvgDoubleCheckMarkComponent} from './svg-components/double-check-mark/double-check-mark.component';
import { PhoneInputComponent } from './partial-components/app-form/phone-input/phone-input.component';
import {DateDirective} from './directives/date.directive';
import { QrModalComponent } from './partial-components/qr-modal/qr-modal.component';
import { QRCodeModule } from 'angularx-qrcode';
import { CardComponent } from './partial-components/card/card.component';
import {BtnPrevPageComponent} from './partial-components/btn-prev-page/btn-prev-page.component';
import {TimerComponent} from './partial-components/timer/timer.component';
import {LangSelectorComponent} from "./vertical-menu/lang-selector/lang-selector.component";
import {DataSelectTableComponent} from "./partial-components/data-select-table/data-select-table.component";
import {ModalWrapperComponent} from "./partial-components/modal-wrapper/modal-wrapper.component";
import { NgxSpinnerModule } from "ngx-spinner";

@NgModule({
  exports: [
    QuillModule,
    CommonModule,
    FooterComponent,
    NavbarComponent,
    VerticalMenuComponent,
    DataTableComponent,
    DataSelectionTableComponent,
    ModalComponent,
    CustomizerComponent,
    NotificationSidebarComponent,
    ToggleFullscreenDirective,
    SidebarDirective,
    TopMenuDirective,
    NgbModule,
    TranslateModule,
    InputComponent,
    AutocompleteDropdownComponent,
    DatePickerFromToComponent,
    DropDownComponent,
    MultiselectFilterComponent,
    MultiselectDropDownComponent,
    CheckboxComponent,
    DatePickerComponent,
    GridFiltersComponent,
    ImageSelectComponent,
    MultiImageSelectComponent,
    SingleDropdownComponent,
    CropperComponent,
    NgSelectModule,
    EditorComponent,
    BackButtonDirective,
    SvgEditComponent,
    SvgDeleteComponent,
    SvgDoubleCheckMarkComponent,
    PhoneInputComponent,
    DateDirective,
    CardComponent,
    BtnPrevPageComponent,
    TimerComponent,
    QrModalComponent,
    ModalWrapperComponent,
    DataSelectTableComponent,
    LangSelectorComponent
  ],
    imports: [
        RouterModule,
        CommonModule,
        NgbModule,
        TranslateModule,
        FormsModule,
        OverlayModule,
        NgSelectModule,
        ReactiveFormsModule,
        PerfectScrollbarModule,
        ClickOutsideModule,
        AutocompleteModule,
        PipeModule,
        ImageCropperModule,
        SwiperModule,
        QuillModule.forRoot(),
        MatCheckboxModule,
        MatTableModule,
        QRCodeModule,
        NgxSpinnerModule
    ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    VerticalMenuComponent,
    LangSelectorComponent,
    DataTableComponent,
    DataSelectionTableComponent,
    DataSelectTableComponent,
    ModalComponent,
    QrModalComponent,
    CustomizerComponent,
    NotificationSidebarComponent,
    ToggleFullscreenDirective,
    SidebarLinkDirective,
    SidebarDropdownDirective,
    SidebarAnchorToggleDirective,
    SidebarDirective,
    TopMenuLinkDirective,
    TopMenuDropdownDirective,
    TopMenuAnchorToggleDirective,
    TopMenuDirective,
    InputComponent,
    AutocompleteDropdownComponent,
    DatePickerFromToComponent,
    DropDownComponent,
    MultiselectFilterComponent,
    MultiselectDropDownComponent,
    CheckboxComponent,
    DatePickerComponent,
    ModalWrapperComponent,
    GridFiltersComponent,
    ImageSelectComponent,
    CropperComponent,
    MultiImageSelectComponent,
    SingleDropdownComponent,
    EditorComponent,
    BackButtonDirective,
    SvgEditComponent,
    SvgDeleteComponent,
    SvgDoubleCheckMarkComponent,
    PhoneInputComponent,
    DateDirective,
    CardComponent,
    BtnPrevPageComponent,
    TimerComponent
  ]
})
export class SharedModule {
}
