<section id="swiper-multiple">

          <div class="card ">
              <img *ngIf="images == null || images.length == 0" style="height: 150px; width: 150px;" class="img-fluid" [src]="defautImage" alt="banner">
              <div class="card-content">
                  <div class="card-body">

                      <div #swiper class="swiper-multiple swiper-container" [swiper]="swiperMultipleConfig" >
                          <div class="swiper-wrapper">

                            <div *ngFor="let i of images" class="swiper-slide position-relative">
                              <button (click)="deleteImg(i)" type="button" class="position-absolute "><img src="assets/close.png"></button>
                              <img style="height: 150px; width: 150px;" class="img-fluid" [src]="i  | urlImage" alt="banner">
                            </div>
                          </div>
                          <!-- Add Pagination -->
                          <div class="swiper-pagination"></div>
                      </div>
                      <label class="custom-file mt-2 pl-0 d-flex flex-column">
                        <span class="btn btn-sm btn-primary mb-1 mb-sm-0 ml-2 w-25">Выбрать</span>

                        <input type="file" hidden accept="image/*" (change)="process($event)" multiple>
                        <button type="button" (click)="reset()" class="btn btn-sm bg-light-secondary ml-sm-2 mt-2 w-25">СБРОС</button>
                     </label>

                  </div>
              </div>
          </div>

</section>
