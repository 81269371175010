<!-- <section id="quill-editor">
  <div class="row">
    <div class="col-12">
      <div class="card text-left">
        <div class="card-body">
          <form [formGroup]="form">
            <quill-editor #editor [style.display]="hide ? 'none' : 'block'" formControlName="editor"></quill-editor>
            <p class="mt-2">
              Output : {{form.controls.editor.value}}
            </p>
          </form>
        </div>
      </div>
    </div>
  </div>
</section> -->

<form [formGroup]="form">
  <quill-editor #editor formControlName="editor"></quill-editor>
</form>