<div class="form-group">
  <div class="controls">
    <label for="users-edit-country">{{name}}</label>
    <div class="input-group">
      <input class="form-control" [readonly] = "true" [minDate]="minDate" [maxDate]="maxDate" (ngModelChange)="onChange($event)" placeholder="yyyy-mm-dd" name="dp" [(ngModel)]='valueForBinding' ngbDatepicker
        #d2="ngbDatepicker">
      <div class="input-group-append">
        <div class="input-group-text" (click)="d2.toggle()">
          <i class="fa fa-calendar" style="cursor: pointer;"></i>
        </div>
      </div>
    </div>
  </div>
</div>
