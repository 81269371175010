import {
  Component,
  OnInit,
  Injector,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  HostBinding,
  ChangeDetectorRef,
} from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { ProxyService } from "../../../../services/proxy.service";
import {
  debounceTime,
  distinctUntilChanged,
  tap,
  debounce,
} from "rxjs/operators";
import { fromEvent } from "rxjs";

@Component({
  selector: "app-autocomplete-dropdown",
  templateUrl: "./autocomplete-dropdown.component.html",
  styleUrls: ["./autocomplete-dropdown.component.scss"],
})
export class AutocompleteDropdownComponent {
  @ViewChild("searchinput") input: any;
  constructor(private proxy: ProxyService, private ref: ChangeDetectorRef) {}
  ngAfterViewInit() {
    fromEvent(this.input.element, "input")
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        tap((text) => {
          this.getData(this.input.searchTerm);
        })
      )
      .subscribe();
    this.getData("");
  }
  @HostBinding("class") class = "col-12 col-sm-6 col-lg-3";


  items = [];
  @Input() propName: any;
  @Input() readonly: any;
  @Input() name: any;
  value = null
  @Output() valueChange = new EventEmitter<any>();
  @Input() source: any;
  @Input() filter: any;
  @Input() sourceParametrs: any;
  @Input() multiple: boolean;
  @Input() isShowIds = true;
  onChange() {
    this.valueChange.emit(this.value);
  }

  getData(event) {
    let res = {};
    if (this.filter) {
      res = {
        take: 20,
        skip: 1,
      };
    }
    if (event != null && event.trim() != "") {
      res[this.propName] = event;

      if (this.propName === "brandId") {
        return
      }
    }
    let src = this.source;
    if (this.source.split("?").length > 1) {
      res = {
        ...res,
        ...JSON.parse(
          '{"' +
            decodeURI(this.source.split("?")[1])
              .replace(/"/g, '\\"')
              .replace(/&/g, '","')
              .replace(/=/g, '":"') +
            '"}'
        ),
      };
      src = this.source.split("?")[0];
    }


    this.proxy.get(src, res, null).subscribe((data) => {
      this.items = (data as any).data.entities;
      // Если при генерации указан value(id),
      // то по этому id находим нужный item и подставляем для отрисовки этого item
      if (this.value) {
        const filteredValue = this.items.find(item => item.id === this.value);
        if (filteredValue) {
          this.value = filteredValue;
        }
      }
      this.ref.detectChanges();
    });
  }
}
