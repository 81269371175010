import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {catchError, map, startWith} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {Router} from '@angular/router';
import {Observable, of} from 'rxjs';
import {ResponseModel} from '../models/response.model';
import {StorageService, StorageValue} from './storage.service';
import {DeleteNullsUtility} from '../utils/delete-null.utility';
import {NgxSpinnerService} from 'ngx-spinner';
import {CustomHttpParamEncoder} from '../utils/custom-http-param-encoder.utility'

const BACKEND_URL = environment.url;

@Injectable({
  providedIn: 'root',
})
export class ProxyService {
  constructor(private http: HttpClient, private router: Router, private spinner: NgxSpinnerService) {
  }

  get<T>(url, filter, headers: HttpHeaders, showSpinner = true): Observable<ResponseModel<T>> {
    if (showSpinner) {
      this.spinner.show(undefined,
        {
          type: 'ball-triangle-path',
          size: 'medium',
          bdColor: 'rgba(0, 0, 0, 0.8)',
          color: '#fff',
          fullScreen: true
        });
    }


    DeleteNullsUtility.deteteNulls(filter);

    let user = StorageService.get(StorageValue.User);
    if (headers != null && user != null) {
      headers.set('Authorization', user.token);
    } else if (user != null) {
      headers = new HttpHeaders({
        Authorization: user.token,
      });
    }

    return this.http
      .get<ResponseModel<T>>(BACKEND_URL + url, {
        params: this.createHttpParam(filter),
        headers: headers,
      })
      .pipe(
        map((response) => {
          if (showSpinner) {
            this.spinner.hide();
          }
          return response as ResponseModel<T>;
        }),
        catchError((err) => {
          if (err.status == 401 || err.status == 403) {
            err.hasError = true;
            err.errors = [{ description: 'Доступ запрещен, войдите под другим пользователем' }];
            this.router.navigate(['/login'], { queryParams: { accessDenied: true } } );
          }
          if (err.status >= 400 && err.status < 500) {
            err.hasError = true;
            err.errors = [{ description: 'Непредвиденная ошибка (Client error)' }]
          }
          if (err.status >= 500 && err.status < 600) {
            err.hasError = true;
            err.errors = [{ description: 'Непредвиденная ошибка (Server error)' }]
          }
          if (showSpinner) {
            this.spinner.hide();
          }
          return of(err);
        })
      );
  }

  post<T>(url, filter, headers: HttpHeaders): Observable<ResponseModel<T>> {
    DeleteNullsUtility.deteteNulls(filter);

    let user = StorageService.get(StorageValue.User);
    if (headers != null && user != null) {
      headers.set('Authorization', user.token);
    } else if (user != null) {
      headers = new HttpHeaders({
        Authorization: user.token,
      });
    }
    return this.http
      .post<ResponseModel<T>>(BACKEND_URL + url, filter, {headers: headers})
      .pipe(
        map((response) => {
          // todo для глобального рефакторинга: убрать кастомный вывод сообщений из отдельных компонентов, сделать глобальный вывод ошибок здесь через toast (рефакторинг не входил в задачу #32383)
          return response as ResponseModel<T>;
        }),
        catchError((err) => {
          if (err.status == 401 || err.status == 403) {
            err.hasError = true;
            err.errors = [{ description: 'Доступ запрещен, войдите под другим пользователем' }];
            this.router.navigate(['/login'], { queryParams: { accessDenied: true } } );
          }
          if (err.status >= 400 && err.status < 500) {
            err.hasError = true;
            err.errors = [{ description: 'Непредвиденная ошибка (Client error)' }]
          }
          if (err.status >= 500 && err.status < 600) {
            err.hasError = true;
            err.errors = [{ description: 'Непредвиденная ошибка (Server error)' }]
          }
          return of(err);
        })
      );
  }

  public async downloadResource(
    url,
    filter,
    headers: HttpHeaders
  ): Promise<any> {
    let user = StorageService.get(StorageValue.User);
    if (headers != null && user != null) {
      headers.set('Authorization', user.token);
    } else if (user != null) {
      headers = new HttpHeaders({
        Authorization: user.token,
      });
    }

    const file = await this.http
      .get(BACKEND_URL + url, {
        responseType: 'blob' as 'json',
        headers: headers,
        params: filter,
        observe: 'response',
      })
      .toPromise();
    return file;
  }

  createHttpParam(params: object): HttpParams {
    let httpParams = new HttpParams({encoder: new CustomHttpParamEncoder()});
    for (let param in params) {
      httpParams = httpParams.set(param, params[param]);
    }
    return httpParams;
  }
}

export enum Urls {
  EmployeeGetAll = 'employee/get-all',
  DeleteEmployee = 'employee/delete',
  UpdateEmployee = 'employee/update',
  AddEmployee = 'employee/add',

  BrandsGetAll = 'brand/get-all',
  DeleteBrand = 'brand/delete',
  UpdateBrand = 'brand/update',
  AddBrand = 'brand/add',
  BrandGetById = 'brand/get-by-id',

  ProductGetById = 'product/get-by-id',
  ProductGetAll = 'product/get-all',
  ProductAdd = 'product/add',
  ProductUpdate = 'product/update',
  ProductDelete = 'product/delete',

  UnitTypeGetAll = 'unit-type/get-all',
  UnitTypeGetById = 'unit-type/get-by-id',
  UnitTypeAdd = 'unit-type/add',
  UnitTypeUpdate = 'unit-type/update',
  UnitTypeDelete = 'unit-type/delete',

  CategorySettingGetAll = 'category-setting/get-all',
  CategorySettingGetById = 'category-setting/get-by-id',
  CategorySettingAdd = 'category-setting/add',
  CategorySettingUpdate = 'category-setting/update',
  CategorySettingDelete = 'category-setting/delete',

  CategorySettingGroupGetAll = 'category-setting-group/get-all',
  CategorySettingGroupGetById = 'category-setting-group/get-by-id',
  categorySettingGroupAdd = 'category-setting-group/add',
  CategorySettingGroupUpdate = 'category-setting-group/update',
  CategorySettingGroupDelete = 'category-setting-group/delete',

  CountryGetAll = 'country/get-all',

  CategoryGetAll = 'category/get-all',
  CategoryGetTree = 'category/get-category-tree',
  CategoryById = 'category/get-by-id',
  categoryAdd = 'category/add',
  CategoryUpdate = 'category/update',
  CategoryDelete = 'category/delete',
  BranchGetAll = 'branch/get-all',
  BranchGetById = 'branch/get-by-id',
  AddBranch = 'branch/add',
  UpdateBranch = 'branch/update',
  DeleteBranch = 'branch/delete',

  StaticTextGetAll = 'static-text/get-all',
  StaticTextGetWebPages = 'static-text/get-web-pages',
  StaticTextGetWebParts = 'static-text/get-web-parts',
  AddStaticText = 'static-text/add',
  UpdateStaticText = 'static-text/update',

  MessageTemplateGetAll = 'message-template/get-all',
  MessageTemplateGetById = 'message-template/get-by-id',
  UpdateMessageTemplate = 'message-template/update',

  RelatedRequestGetAll = 'keyword-suggestions/get-all',
  RelatedRequestGetById = 'keyword-suggestions/get-by-id',
  AddRelatedRequest = 'keyword-suggestions/add',
  UpdateRelatedRequest = 'keyword-suggestions/update',
  DeleteRelatedRequest = 'keyword-suggestions/delete',

  FoundCheaperGetAll = 'found-cheaper/get-all',
  FoundCheaperGetById = 'found-cheaper/get-by-id',
  FoundCheaperUpdate = 'found-cheaper/update',
  FoundCheaperDelete = 'found-cheaper/delete',
  FoundCheaperExportExcel = 'export-excel/export-to-excel-found-cheapers',

  SubscribeGetAll = 'subscribe-available/get-all',
  SubscribeGetById = 'subscribe-available/get-by-id',
  SubscribeUpdate = 'subscribe-available/update',
  SubscribeExportExcel = 'export-excel/export-to-excel-subscribe-on-available-products',

  CheckOrderStatusGetAll = 'check-order-status/get-all',
  CheckOrderStatusGetById = 'check-order-status/get-by-id',
  CheckOrderStatusUpdate = 'check-order-status/update',
  CheckOrderStatusDelete = 'check-order-status/delete',
  CheckOrderStatusExportExcel = 'export-excel/export-to-excel-order-status-checks',

  UserGetAll = 'user/get-all',
  UserGetById = 'user/get-by-id',
  UserUpdate = 'user/update',
  UserDelete = 'user/delete',

  BannerGetAll = 'banner/get-all',
  BannerById = 'banner/get-by-id',
  BannerUpdate = 'banner/update',
  BannerDelete = 'banner/delete',
  BannerAdd = 'banner/add',

  WorkChatAll = 'workChat/get-all-chats-without-messages',
  WorkChatsById = 'workChat/get-messages-paged',
  WorkChatsByManagerId = 'workChat/get-chat-paged',
  WorkChatsMarkReadUpdate = 'workChat/mark-messages-as-read',
  WorkChatsUnread = 'workChat/get-count-unread-chats',

  ParentChat = 'parentChat/get-all-chats-without-messages',
  ParentChatById = 'parentChat/get-chat-by-id',
  ParentChatMarkReadUpdate = 'parentChat/mark-messages-as-read',
  ParentChatUnread = 'parentChat/get-count-unread-chats',

  ParentGetAll = 'parent/get-all',

  SubGetAll = 'subscribe/get-all',
  SubGetById = 'subscribe/get-by-id',
  SubExportExcel = 'export-excel/export-to-excel-subscribes',

  ThemeGetAll = 'callback-theme/get-all',
  ThemeById = 'callback-theme/get-by-id',
  ThemeUpdate = 'callback-theme/update',
  ThemeDelete = 'callback-theme/delete',
  ThemeAdd = 'callback-theme/add',

  QuestionGetAll = 'callback-question/get-all',
  QuestionById = 'callback-question/get-by-id',
  QuestionUpdate = 'callback-question/update',
  QuestionDelete = 'callback-question/delete',
  QuestionAdd = 'callback-question/add',

  FaqGroupGetAll = 'faq-group/get-all',
  FaqGroupById = 'faq-group/get-by-id',
  FaqGroupUpdate = 'faq-group/update',
  FaqGroupDelete = 'faq-group/delete',
  FaqGroupAdd = 'faq-group/add',

  FaqGetAll = 'faq/get-all',
  FaqById = 'faq/get-by-id',
  FaqUpdate = 'faq/update',
  FaqDelete = 'faq/delete',
  FaqAdd = 'faq/add',

  OrderGetAll = 'order/get-all',
  OrderById = 'order/get-by-id',
  OrderUpdate = 'order/update',
  OrderExportExcel = 'export-excel/export-to-excel-orders',

  // LogOut = 'employee-session/log-out',

  ////// okid apis
  ClientsGetAll = 'user/get-all',
  AddClient = 'user/add',
  UpdateClient = 'user/update',
  GetClientById = 'user/get-by-id',
  DeleteClient = 'user/delete',

  ParentsGetAll = 'parent/get-all',
  GetParentById = 'parent/get-by-id',
  ParentRegister = 'parent/register',
  ParentVerify = 'parent/verification',
  ParentDelete = 'parent/delete',
  ParentUpdate = 'parent/update',
  GetParentCustomerId = 'parent/get-customer-id',
  ParentGeneratePhoneCode = 'parent/generate-phone-verification-code',

  CheckListGetAll = 'checklist/get-all',
  AddCheckList = 'checklist/add',
  UpdateCheckList = 'checklist/update',
  GetCheckListById = 'checklist/get-by-id',
  DeleteCheckList = 'checklist/delete',

  LogOut = 'client-session/log-out',
  GetPlaygroundIds = 'playground/get-all',
  GetPlaygroundById = 'playground/get-by-id',
  AddPlayground = 'playground/add',
  UpdatePlayground = 'playground/update',
  DeletePlayground = 'playground/delete',
  ClosePlayground = 'playground/close',
  GetGender = 'gender/get-all',

  GetFiscalRegister = 'fiscal-register/get-all',
  GetFiscalRegisterById = 'fiscal-register/get-by-id',
  AddFiscalRegister = 'fiscal-register/add',
  UpdateFiscalRegister = 'fiscal-register/update',
  CloseFiscalRegister = 'fiscal-register/close-shift',
  OpenFiscalRegister = 'fiscal-register/open-shift',
  DeleteFiscalRegister = 'fiscal-register/delete',
  RepeatFiscal = 'fiscal-task/repeat-sale-document',
  MakeReceiptCopy = 'fiscal-register/make-receipt-copy',
  FindReceiptOnCashdesk = 'fiscal-task/fill-fiscal-task',
  SetDefaultFiscalRegister = 'fiscal-register/set-default-fiscal',

  AddUtility = 'utility/add',
  GelAllUtility = 'utility/get-all',
  GelUtilityById = 'utility/get-by-id',
  DeleteUtility = 'utility/delete',
  UpdateUtility = 'utility/update',

  ShiftHistory = 'shift/get-history',
  ChatHistory = 'parentChat/get-all-chats-without-messages',
  ViolationList = 'violation/get-all',
  ViolationCreate = 'violation/add',

  GetAdditionalProducts = 'stripe/additional_products',

  GetAllLanguages = 'language/get-all',

  ChildAdd = 'child/add-children',
  ChildUpdate = 'child/update-child',
  ChildRemove = 'child/remove',
  ChildGet = 'child/get-personal-info',

  StripeOrdersAll = 'order/get-all',
  StripeOrdersById = 'order/get-by-id',
  StripeOrdersSendVerificationCode = 'order/send-verification-code',
  StripeOrdersCheckVerificationCode = 'order/check-verification-code',

  PaymentChannelAll = 'payment-channel/get-all',

  MakeTempOrder = 'order/make-temp-order',
  MakeOrder = 'order/make-order',

}
