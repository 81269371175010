import { Injectable } from '@angular/core';
import { ProxyService, Urls } from "./proxy.service";
import { map } from "rxjs/operators";
import { PagedResponse } from "../models/paged-response.model";

@Injectable({
  providedIn: 'root',
})
// @ts-ignore
export class FiscalRegisterService {
  constructor(private proxyService: ProxyService) {
  }

  getAllFiscalRegister() {
    return this.proxyService.get<PagedResponse<any>>(Urls.GetFiscalRegister, {}, null)
      .pipe(map(result => {
        if (result.hasError) {
          console.error(result);
          return [];
        }
        return result.data?.entities;
      }));
  }

  addFiscalRegister(request) {
    return this.proxyService.post(Urls.AddFiscalRegister, request, null);
  }

  getFiscalRegisterById(id: number) {
    return this.proxyService.get<any>(Urls.GetFiscalRegisterById, {fiscalRegisterId: id}, null)
  }

  updateFiscalRegister(request) {
    return this.proxyService.post(Urls.UpdateFiscalRegister, request, null);
  }

  closeFiscalRegister(id: number) {
    return this.proxyService.post(Urls.CloseFiscalRegister, {id: id}, null);
  }

  openFiscalRegister(id: number) {
    return this.proxyService.post(Urls.OpenFiscalRegister, {id: id}, null);
  }

  deleteFiscalRegister(id: number) {
    return this.proxyService.post(Urls.DeleteFiscalRegister, {fiscalRegisterId: id}, null)
      .pipe(map(res => {
        if (res == null) {
          res = {
            ...res,
            hasError: false
          }
        }
        return res
      }));
  }

  makeReceiptCopy(id: number) {
    return this.proxyService.post(Urls.MakeReceiptCopy, {orderId: id}, null);
  }

  repeatFiscal(id: number) {
    return this.proxyService.post(Urls.RepeatFiscal, {orderId: id}, null);
  }

  findReceiptOnCashdesk(request) {
    return this.proxyService.post(Urls.FindReceiptOnCashdesk, request, null);
  }

  setDefaultFiscalRegister(request) {
    return this.proxyService.post(Urls.SetDefaultFiscalRegister, request, null)
      .pipe(map(res => {
        if (res == null) {
          res = {
            ...res,
            hasError: false
          }
        }
        return res
      }));
  }

  getOrderById(id: number) {
    return this.proxyService.get<PagedResponse<any>>(Urls.StripeOrdersAll, {}, null)
      .pipe(map(res => {
        return res.data;
      }));
  }
}
