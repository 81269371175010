import {Component} from '@angular/core';

@Component({
  selector: 'app-btn-prev-page',
  templateUrl: 'btn-prev-page.component.html',
  styleUrls: ['btn-prev-page.component.scss']
})
export class BtnPrevPageComponent {

}
