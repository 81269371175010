import { Component, OnInit, Injector, Input, ViewChild, ElementRef, Output, EventEmitter, HostBinding, ChangeDetectorRef, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { observable } from 'core-js/fn/symbol';
import { environment } from 'environments/environment.prod';
import { CropperComponent } from '../cropper/cropper.component';
import { ToastService } from "../../services/toast.service";


@Component({
  selector: 'app-image-select',
  templateUrl: './image-select.component.html',
  styleUrls: ['./image-select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ImageSelectComponent),
    multi: true
  }]
})
export class ImageSelectComponent implements ControlValueAccessor  {
  defautImage = 'assets/img/no-image.png';
  @Input() hasMultiOnPage: boolean = false;
  @Input() isBig: boolean = false;
  public selected: boolean = false;

  private onChange: Function;
  private onTouched: Function;

  constructor(private ref: ChangeDetectorRef,private modalService: NgbModal, private toast: ToastService){
    this.onChange = (_: any) => {};
    this.onTouched = () => {};
  }

  writeValue(obj: any): void {
    if(obj == null){
      this.image = this.defautImage;
    }
    else{
      if(obj[0] == '/'){
        this.image = obj;
      }
      else{
        this.image = obj;
      }
    }

    this.ref.detectChanges();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  image = this.defautImage;

  process(event){
    const file = event.target.files[0];
    if (file) {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (fileExtension === 'svg' || fileExtension === 'tiff') {
        this.toast.error('toast_notices.not_available_file_format');
        return;
      }
    }
    this.ref.detectChanges();
    const modalRef = this.modalService.open(CropperComponent, {backdrop: 'static', keyboard: false});
    modalRef.componentInstance.imageChangedEvent =  event;
    if(this.isBig){
      modalRef.componentInstance.aspectRatio = 1180 / 276;
    }

    modalRef.result.then((base64) => {
      if(base64){
        this.image = base64;
        this.onChange(this.image);
        this.selected = true;
        this.ref.detectChanges();
      }
      event.target.value = null;
    });
  }

  reset() {
    this.image = this.defautImage;
    this.onChange(null);
    this.selected = false;
  }

  getBase64(file, callback) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      callback(reader.result);
    };
    reader.onerror = function (error) {
      callback(null);
    };
 }
}
