import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ProxyService, Urls } from './proxy.service';
import { hasError } from '../utils/errors.utility';
import {  map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ParentChatService {
  private countUnreadChats$ = new BehaviorSubject<number>(0);

  constructor(private proxy: ProxyService) {
    this.setCountUnreadChats();
  }

   getCountUnreadChats() {
    return this.countUnreadChats$;
  }

  public setCountUnreadChats() {
    return this.proxy.get<number>(Urls.ParentChatUnread, {}, null)
      .pipe(
        map(res => {
          if (!hasError(res)) {
            this.countUnreadChats$.next(res.data);
          }
          return res;
        })
      );
  }

}
