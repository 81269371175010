import {Component, ViewContainerRef, OnInit, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {Router, NavigationEnd} from '@angular/router';
import {filter} from 'rxjs/operators';
import {StaticTextsService} from '../app/shared/services/static-texts.service'
import {SignalrService} from './shared/services/signalr.service';
import {StorageService, StorageValue} from './shared/services/storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  subscription: Subscription;

  constructor(private router: Router,
              private staticTextsService: StaticTextsService,
              private signalrService: SignalrService) {
  }

  async ngOnInit() {
    const token = StorageService.get(StorageValue.User)?.token;
    if (token !== null && token?.trim() !== '') {
      await this.signalrService.initiateSignalrConnection();
    }
    // const staticTexts: any = await this.staticTextsService.getStaticTexts();
    // if (!staticTexts.hasError) {
    //   this.staticTextsService.staticTexts = staticTexts.data;
    // }

    this.subscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => window.scrollTo(0, 0));
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
