import { RouteInfo } from './vertical-menu.metadata';
import { UserTypeEnum } from '../enums/user-type.enum';

// Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
  {
    path: '/playground',
    title: 'playgrounds.playgrounds',
    icon: 'ft-align-left',
    class: '',
    badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
    isExternalLink: false,
    access: [UserTypeEnum.Director],
    submenu: [],
  },
  {
    path: '/cashdesk',
    title: 'common.cash_register',
    icon: 'ft-align-left',
    class: '',
    badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
    isExternalLink: false,
    access: [UserTypeEnum.Director, UserTypeEnum.Manager],
    submenu: [],
  },
  {
    path: '/stripe-orders',
    title: 'orders.orders',
    icon: 'ft-align-left',
    class: '',
    badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
    isExternalLink: false,
    access: [UserTypeEnum.Director, UserTypeEnum.Manager],
    submenu: [],
  },
  {
    path: '/services',
    title: 'nomenclature.nomenclature',
    icon: 'ft-align-left',
    class: '',
    badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
    isExternalLink: false,
    access: [UserTypeEnum.Director],
    submenu: [],
  },
  {
    path: '',
    title: 'common.chats',
    icon: 'ft-align-left',
    class: 'has-sub',
    badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
    isExternalLink: false,
    access: [UserTypeEnum.Director, UserTypeEnum.Manager, UserTypeEnum.Monitoring],
    submenu: [
      {
        path: '/staff-chats',
        title: 'staff.staff',
        icon: 'ft-arrow-right submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        access: [UserTypeEnum.Director, UserTypeEnum.Manager],
        submenu: [],
      },
      {
        path: '/parrent-chats',
        title: 'common.parents',
        icon: 'ft-arrow-right submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        access: [UserTypeEnum.Director, UserTypeEnum.Manager],
        submenu: [],
      },
      {
        path: '/monitoring-chat',
        title: 'common.monitoring',
        icon: 'ft-arrow-right submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        access: [UserTypeEnum.Director],
        submenu: [],
      },

    ],
  },
  {
    path: '/banners',
    title: 'common.banners',
    icon: 'ft-align-left',
    class: '',
    badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
    isExternalLink: false,
    access: [UserTypeEnum.Director],
    submenu: [],
  },
  {
    path: '',
    title: 'common.static_elements',
    icon: 'ft-align-left',
    class: 'has-sub',
    badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
    isExternalLink: false,
    access: [UserTypeEnum.Director],
    submenu: [
      {
        path: '/static-elements-colors',
        title: 'common.colors_of_elements',
        icon: 'ft-arrow-right submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/static-elements-requisites',
        title: 'common.requisites',
        icon: 'ft-arrow-right submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
    ],
  },
  {
    path: '/notifications',
    title: 'common.notifications',
    icon: 'ft-align-left',
    class: '',
    badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
    isExternalLink: false,
    access: [UserTypeEnum.Director],
    submenu: [],
  },
  {
    path: '/admin-users',
    title: 'staff.staff',
    icon: 'ft-align-left',
    class: '',
    badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
    isExternalLink: false,
    access: [UserTypeEnum.Director],
    submenu: [],
  },
  {
    path: '/users-list',
    title: 'users.users',
    icon: 'ft-align-left',
    class: '',
    badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
    isExternalLink: false,
    access: [UserTypeEnum.Director, UserTypeEnum.Manager],
    submenu: [],
  },
  {
    path: '/children',
    title: 'register_of_children.register_of_children',
    icon: 'ft-align-left',
    class: '',
    badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
    isExternalLink: false,
    access: [UserTypeEnum.Director],
    submenu: [],
  },
  {
    path: '/promocodes',
    title: 'common.promocodes',
    icon: 'ft-align-left',
    class: '',
    badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
    isExternalLink: false,
    access: [UserTypeEnum.Director],
    submenu: [],
  },
  {
    path: '/check-list',
    title: 'common.check_list',
    icon: 'ft-align-left',
    class: '',
    badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
    isExternalLink: false,
    access: [UserTypeEnum.Director],
    submenu: [],
  },
  {
    path: '/mobile-app',
    title: 'mobile_app.mobile_app',
    icon: 'ft-align-left',
    class: '',
    badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
    isExternalLink: false,
    access: [UserTypeEnum.Director],
    submenu: [],
  },
  {
    path: '/fiscal-register',
    title: 'fiscalization.register_of_fiscal_registrars',
    icon: 'ft-align-left',
    class: '',
    badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
    isExternalLink: false,
    access: [UserTypeEnum.Director],
    submenu: [],
  },

  // {
  //   path: "",
  //   title: "Чаты",
  //   icon: "ft-align-left",
  //   class: "",
  //   badge: "",
  //   badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
  //   isExternalLink: false,
  //   submenu: [],
  // },
  // {
  //   path: "",
  //   title: "Баннеры",
  //   icon: "ft-align-left",
  //   class: "",
  //   badge: "",
  //   badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
  //   isExternalLink: false,
  //   submenu: [],
  // },
  // {
  //   path: "",
  //   title: "Статические элементы",
  //   icon: "ft-align-left",
  //   class: "",
  //   badge: "",
  //   badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
  //   isExternalLink: false,
  //   submenu: [],
  // },
  // {
  //   path: "",
  //   title: "Товары и Ассортимент",
  //   icon: "ft-align-left",
  //   class: "has-sub",
  //   badge: "",
  //   badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
  //   isExternalLink: false,
  //   submenu: [
  //     {
  //       path: "/catalog",
  //       title: "Каталог",
  //       icon: "ft-arrow-right submenu-icon",
  //       class: "",
  //       badge: "",
  //       badgeClass: "",
  //       isExternalLink: false,
  //       submenu: [],
  //     },
  //     {
  //       path: "/settings",
  //       title: "Группы настроек",
  //       icon: "ft-arrow-right submenu-icon",
  //       class: "",
  //       badge: "",
  //       badgeClass: "",
  //       isExternalLink: false,
  //       submenu: [],
  //     },
  //     {
  //       path: "/products",
  //       title: "Товары",
  //       icon: "ft-arrow-right submenu-icon",
  //       class: "",
  //       badge: "",
  //       badgeClass: "",
  //       isExternalLink: false,
  //       submenu: [],
  //     },
  //     {
  //       path: "/brands",
  //       title: "Бренды",
  //       icon: "ft-arrow-right submenu-icon",
  //       class: "",
  //       badge: "",
  //       badgeClass: "",
  //       isExternalLink: false,
  //       submenu: [],
  //     },
  //     {
  //       path: "/found-cheaper",
  //       title: "Нашли дешевле",
  //       icon: "ft-arrow-right submenu-icon",
  //       class: "",
  //       badge: "",
  //       badgeClass: "",
  //       isExternalLink: false,
  //       submenu: [],
  //     },
  //     {
  //       path: "/notify-on-admission",
  //       title: "Сообщить о поступлении",
  //       icon: "ft-arrow-right submenu-icon",
  //       class: "",
  //       badge: "",
  //       badgeClass: "",
  //       isExternalLink: false,
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: "",
  //   title: "Заказы",
  //   icon: "ft-align-left",
  //   class: "has-sub",
  //   badge: "",
  //   badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
  //   isExternalLink: false,
  //   submenu: [
  //     {
  //       path: "/placed-orders",
  //       title: "Заказы",
  //       icon: "ft-arrow-right submenu-icon",
  //       class: "",
  //       badge: "",
  //       badgeClass: "",
  //       isExternalLink: false,
  //       submenu: [],
  //     },
  //     {
  //       path: "/order-status",
  //       title: "Проверка статуса заказа",
  //       icon: "ft-arrow-right submenu-icon",
  //       class: "",
  //       badge: "",
  //       badgeClass: "",
  //       isExternalLink: false,
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: "",
  //   title: "Пользователи",
  //   icon: "ft-align-left",
  //   class: "has-sub",
  //   badge: "",
  //   badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
  //   isExternalLink: false,
  //   submenu: [
  //     {
  //       path: "/users",
  //       title: "Пользователи",
  //       icon: "ft-arrow-right submenu-icon",
  //       class: "",
  //       badge: "",
  //       badgeClass: "",
  //       isExternalLink: false,
  //       submenu: [],
  //     },
  //     {
  //       path: "/followers",
  //       title: "Подписчики",
  //       icon: "ft-arrow-right submenu-icon",
  //       class: "",
  //       badge: "",
  //       badgeClass: "",
  //       isExternalLink: false,
  //       submenu: [],
  //     },
  //   ],
  // },

  // {
  //   path: "/contacts",
  //   title: "Контакты и реквизиты",
  //   icon: "ft-align-left",
  //   class: "",
  //   badge: "",
  //   badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
  //   isExternalLink: false,
  //   submenu: [],
  // },
  // {
  //   path: "",
  //   title: "Поддержка",
  //   icon: "ft-align-left",
  //   class: "has-sub",
  //   badge: "",
  //   badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
  //   isExternalLink: false,
  //   submenu: [
  //     {
  //       path: "",
  //       title: "Темы и запросы",
  //       icon: "ft-arrow-right submenu-icon",
  //       class: "has-sub",
  //       badge: "",
  //       badgeClass: "",
  //       isExternalLink: false,
  //       submenu: [
  //         {
  //           path: "/support/themes",
  //           title: "Темы",
  //           icon: "ft-arrow-right submenu-icon",
  //           class: "",
  //           badge: "",
  //           badgeClass: "",
  //           isExternalLink: false,
  //           submenu: [],
  //         },
  //         {
  //           path: "/support/questions",
  //           title: "Запросы",
  //           icon: "ft-arrow-right submenu-icon",
  //           class: "",
  //           badge: "",
  //           badgeClass: "",
  //           isExternalLink: false,
  //           submenu: [],
  //         },

  //       ],
  //     },
  //     {
  //       path: "/faq",
  //       title: "FAQ",
  //       icon: "ft-arrow-right submenu-icon",
  //       class: "",
  //       badge: "",
  //       badgeClass: "",
  //       isExternalLink: false,
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: "",
  //   title: "Настройки",
  //   icon: "ft-align-left",
  //   class: "has-sub",
  //   badge: "",
  //   badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
  //   isExternalLink: false,
  //   submenu: [
  //     {
  //       path: "/related-requests",
  //       title: "Похожие запросы",
  //       icon: "ft-arrow-right submenu-icon",
  //       class: "",
  //       badge: "",
  //       badgeClass: "",
  //       isExternalLink: false,
  //       submenu: [],
  //     },
  //     {
  //       path: "/static-texts",
  //       title: "Статистические тексты",
  //       icon: "ft-arrow-right submenu-icon",
  //       class: "",
  //       badge: "",
  //       badgeClass: "",
  //       isExternalLink: false,
  //       submenu: [],
  //     },
  //     {
  //       path: "/templates",
  //       title: "Шаблоны",
  //       icon: "ft-arrow-right submenu-icon",
  //       class: "",
  //       badge: "",
  //       badgeClass: "",
  //       isExternalLink: false,
  //       submenu: [],
  //     },
  //     {
  //       path: "/unit-types",
  //       title: "Единицы измерения",
  //       icon: "ft-arrow-right submenu-icon",
  //       class: "",
  //       badge: "",
  //       badgeClass: "",
  //       isExternalLink: false,
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: "/admin-users",
  //   title: "Сотрудники",
  //   icon: "ft-align-left",
  //   class: "",
  //   badge: "",
  //   badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
  //   isExternalLink: false,
  //   submenu: [],
  // },
];
