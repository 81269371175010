import {DatePipe} from '@angular/common';

export class DateUtility {

  public dateFormatEn = 'yyyy/MM/dd HH:mm';

  convertDate(date, prop) {
    if(date == null){
      return '';
    }
    if(date.includes('Z')) date = date.replace('Z', '');
    return prop.toString()?.toLowerCase()?.includes('date') ? new DatePipe('en-us').transform(new Date(date + 'Z').toISOString(), this.dateFormatEn) : date;
  }
}
