import {
  Component, OnInit, ViewChild, OnDestroy,
  ElementRef, AfterViewInit, ChangeDetectorRef, HostListener
} from '@angular/core';
import { ROUTES } from './vertical-menu-routes.config';

import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ConfigService } from '../services/config.service';
import { combineLatest, forkJoin, Subscription } from 'rxjs';
import { LayoutService } from '../services/layout.service';
import { ProxyService, Urls } from '../services/proxy.service';
import { StorageService, StorageValue } from '../services/storage.service';
import { ParentChatService } from '../services/parent-chat.service';
import { WorkChatService } from '../services/work-chat.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './vertical-menu.component.html'
})
export class VerticalMenuComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('toggleIcon') toggleIcon: ElementRef;
  public menuItems: any[];
  level: number = 0;
  logoUrl = '/favicon.ico';
  public config: any = {};
  protected innerWidth: any;
  protected readonly MenuTitle = MenuTitle;
  public userInfo: any = {};

  layoutSub: Subscription;
  configSub: Subscription;
  parentChatSub: Subscription;
  workChatSub: Subscription;

  perfectScrollbarEnable = true;
  collapseSidebar = false;
  resizeTimeout;

  public countUnreadAllChats = 0;
  public countUnreadParentChats = 0;
  public countUnreadWorkChats = 0;

  logout = {
    path: null, title: 'common.exit', icon: 'ft-home', class: '', badge: '', badgeClass: '', isExternalLink: false,
    submenu: []
  }

  async logOut() {
    await this.proxy.post(Urls.LogOut, {}, null);
    StorageService.delete(StorageValue.User);
    StorageService.delete(StorageValue.CurrentClientCompanyInfo);
    this.router.navigate(['/login']);
  }

  constructor(
    private proxy: ProxyService,
    private router: Router,
    public translate: TranslateService,
    private layoutService: LayoutService,
    private configService: ConfigService,
    private cdr: ChangeDetectorRef,
    private deviceService: DeviceDetectorService,
    private parentChatService: ParentChatService,
    private workChatsService: WorkChatService,
    private ref: ChangeDetectorRef
  ) {
    this.config = this.configService.templateConf;
    this.innerWidth = window.innerWidth;
    this.isTouchDevice();
  }


  ngOnInit() {
    this.initMenuItems();

    forkJoin([
      this.parentChatService.setCountUnreadChats(),
      this.workChatsService.setCountUnreadChats()
    ])
      .subscribe(res => {
        this.setCountsChats();
        this.ref.detectChanges();
      });
  }

  setCountsChats() {
    this.parentChatSub = this.parentChatService.getCountUnreadChats()
      .subscribe(count => {
      this.countUnreadParentChats = count;
    });
    this.workChatSub = this.workChatsService.getCountUnreadChats()
      .subscribe(count => {
      this.countUnreadWorkChats = count;
    });

    combineLatest([
      this.parentChatService.getCountUnreadChats(),
      this.workChatsService.getCountUnreadChats()
    ])
      .subscribe(([pChat, wChat]) => {
        this.countUnreadAllChats = pChat + wChat;
        this.ref.detectChanges();
      });

  }

  ngAfterViewInit() {
    this.configSub = this.configService.templateConf$.subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
      }
      this.loadLayout();
      this.cdr.markForCheck();

    });

    this.layoutSub = this.layoutService.overlaySidebarToggle$.subscribe(
      collapse => {
        if (this.config.layout.menuPosition === 'Side') {
          this.collapseSidebar = collapse;
        }
      });

    this.userInfo = StorageService.get(StorageValue.User);
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(event) {
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }
    this.resizeTimeout = setTimeout((() => {
      this.innerWidth = event.target.innerWidth;
      this.loadLayout();
    }).bind(this), 500);
  }

  loadLayout() {

    if (this.config.layout.menuPosition === 'Top') { // Horizontal Menu

    } else if (this.config.layout.menuPosition === 'Side') { // Vertical Menu{
      this.initMenuItems()
    }


    if (this.config.layout.sidebar.backgroundColor === 'white') {
      this.logoUrl = 'assets/img/logo-dark.png';
    } else {
      this.logoUrl = '/favicon.ico';
    }

    if (this.config.layout.sidebar.collapsed) {
      this.collapseSidebar = true;
    } else {
      this.collapseSidebar = false;
    }
  }

  toggleSidebar() {
    let conf = this.config;
    conf.layout.sidebar.collapsed = !this.config.layout.sidebar.collapsed;
    this.configService.applyTemplateConfigChange({layout: conf.layout});

    setTimeout(() => {
      this.fireRefreshEventOnWindow();
    }, 300);
  }

  fireRefreshEventOnWindow = function () {
    const evt = document.createEvent('HTMLEvents');
    evt.initEvent('resize', true, false);
    window.dispatchEvent(evt);
  };

  CloseSidebar() {
    this.layoutService.toggleSidebarSmallScreen(false);
  }

  isTouchDevice() {

    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();

    if (isMobile || isTablet) {
      this.perfectScrollbarEnable = false;
    } else {
      this.perfectScrollbarEnable = true;
    }

  }

  private initMenuItems() {
    this.menuItems = ROUTES;
  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
    if (this.configSub) {
      this.configSub.unsubscribe();
    }
    this.parentChatSub.unsubscribe();
    this.workChatSub.unsubscribe();
  }

}

export enum MenuTitle {
  Chats = 'Чаты',
  Parents = 'Родители',
  Workers = 'Работники'
}
