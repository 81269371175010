import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-double-check-mark',
  templateUrl: './double-check-mark.component.svg',
  styleUrls: ['./double-check-mark.component.scss']
})
export class SvgDoubleCheckMarkComponent {
  @Input() isActive = false;
}
