<!--table starts-->
<section id="ng-tables">
  <div class="row text-left">
    <div class="col-12">
      <div class="card">
        <ng-content select="[slot='utility']"></ng-content>
        <div class="card-content">
          <div class="card-body">

            <!--<button routerLink="/services/new" type="button" class="btn btn-primary mr-2 m-0"><i class="ft-check-square mr-1"></i>Добавить услугу</button>-->

            <form>
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                  <tr>
                    <th *ngFor="let column of data.extraColumns ">
                      <ng-container [ngSwitch]="column?.type">
                        <span *ngSwitchCase=extraColumnTypes.Selection>
                          <mat-checkbox
                            (change)="$event ? masterToggle() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()"
                          >

                          </mat-checkbox>
                        </span>
                      </ng-container>
                    </th>
                    <th *ngFor="let header of data.headers" scope="col">{{header.displayName | translate}}</th>
                    <th *ngIf="data.actions != null" scope="col">{{data.actions.displayName}}</th>
                  </tr>

                  </thead>
                  <tbody>
                  <td *ngIf="total==0" class="empty-table-info" [attr.colspan]="data.headers.length">
                    {{'common.the_result_not_found' | translate}}
                  </td>
                  <tr [ngClass]="{'active': row == selectedRow}" *ngFor="let row of rows" (click)="rowSelected(row)">
                    <ng-container *ngIf="!row?.isDeleted">
                      <td *ngFor="let column of data.extraColumns; let i = index">
                        <ng-container [ngSwitch]="data.extraColumns[i]?.type">
                          <span *ngSwitchCase="'select'">
                            <mat-checkbox
                              (change)="$event ? selection.toggle(row) : null"
                              (click)="$event.stopPropagation()"
                              [checked]="selection.isSelected(row)"
                            >
                            </mat-checkbox>
                          </span>
                        </ng-container>
                      </td>
                      <td *ngFor="let header of data.headers; let i = index">
                        <ng-container [ngSwitch]="data.headers[i]?.type">
                          <img *ngSwitchCase="'image'" [src]="mediaUrl + row[data.headers[i].property]"
                               onerror="this.src='assets/img/no-image.png';" alt="" style="height: 75px;">
                          <ng-container *ngSwitchCase="null">
                            {{row | gridrowvalue : data.headers[i]?.property}}
                          </ng-container>
                          <ng-container *ngSwitchCase="'datetime'">
                            {{dateUtility.convertDate(row[data.headers[i].property], data.headers[i].property)}}
                            <!-- {{row[data.headers[i].property]}} -->
                          </ng-container>
                          <ng-container *ngSwitchCase="'boolean'">
                            {{(row | gridrowvalue : data.headers[i]?.property) == true ? 'Активна' : 'Не активна'}}
                          </ng-container>
                          <ng-container *ngSwitchCase="'link'">
                            <a href="{{row | gridrowvalue : data.headers[i]?.property}}">
                              {{row | gridrowvalue : data.headers[i]?.property}}
                            </a>
                          </ng-container>
                          <ng-container *ngSwitchCase="'percente'">
                            {{row | gridrowvalue : data.headers[i]?.property}}%
                          </ng-container>
                          <ng-container *ngSwitchCase="'html'">
                            {{row | gridrowvalue : data.headers[i]?.property | removeHtmlTags}}
                          </ng-container>
                          <ng-container *ngSwitchCase="'file'">
                              <span (click)="getFiles()">
                                <svg class="mr-1" width="14" height="14" viewBox="0 0 14 14" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M9.66648 0.333011C10.4452 0.332824 11.207 0.559932 11.8585 0.986474C12.51 1.41302 13.0228 2.02044 13.334 2.73424C13.6453 3.44803 13.7414 4.23715 13.6106 5.00479C13.4798 5.77243 13.1278 6.48521 12.5978 7.05568L12.4711 7.18501L6.65314 13.003L6.61781 13.0363L6.58048 13.0663C6.10653 13.4749 5.49569 13.6893 4.87036 13.6665C4.24503 13.6437 3.65142 13.3854 3.20848 12.9434C2.76555 12.5014 2.50602 11.9083 2.48191 11.283C2.45781 10.6578 2.67089 10.0465 3.07848 9.57168L3.17781 9.46234L3.18781 9.45501L8.04781 4.58568C8.17273 4.46049 8.34226 4.39006 8.5191 4.38988C8.69595 4.38969 8.86563 4.45976 8.99081 4.58468C9.11599 4.70959 9.18642 4.87912 9.18661 5.05597C9.1868 5.23282 9.11673 5.4025 8.99181 5.52768L4.13181 10.397L4.12381 10.4023C3.92765 10.6118 3.81712 10.8872 3.81401 11.1742C3.81091 11.4611 3.91545 11.7388 4.10703 11.9525C4.2986 12.1662 4.5633 12.3003 4.84889 12.3284C5.13449 12.3565 5.42025 12.2766 5.64981 12.1043L5.73114 12.0377L5.73181 12.0383L11.5331 6.23834L11.6391 6.12834C12.1059 5.61532 12.3544 4.94063 12.3319 4.24741C12.3095 3.5542 12.0178 2.89702 11.5188 2.41529C11.0198 1.93357 10.3528 1.6652 9.6592 1.66714C8.96562 1.66908 8.30009 1.94117 7.80381 2.42568L7.70048 2.53234L7.68848 2.54034L1.47114 8.76034C1.4092 8.82224 1.33568 8.87133 1.25477 8.90481C1.17385 8.93829 1.08714 8.95551 0.999574 8.95548C0.912008 8.95545 0.825306 8.93817 0.744418 8.90463C0.66353 8.87109 0.590039 8.82195 0.528143 8.76001C0.466247 8.69807 0.417156 8.62455 0.383675 8.54363C0.350194 8.46272 0.332977 8.37601 0.333008 8.28844C0.33307 8.11159 0.403382 7.94202 0.528476 7.81701L6.73448 1.60834L6.76514 1.57968C7.13855 1.18532 7.58853 0.871336 8.08754 0.656971C8.58654 0.442606 9.12405 0.332368 9.66714 0.333011H9.66648Z"
                                    fill="black"/>
                                </svg>
                                <span class="mr-1">{{row[data.headers[i].property]}}</span>
                                <span>файлов</span>
                              </span>
                          </ng-container>
                        </ng-container>

                      </td>
                      <td *ngIf="data.actions != null" class="actions-td d-flex">
                        <a *ngIf="getAction(actionType.Edit) != null" class="success p-0 mr-2"
                           (click)="setSourceAndNavigate(actionType.Edit, row)">
                          <!-- <i class="ft-edit-2 font-medium-3 mr-2"></i> -->
                          <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M0.333008 10.8899V13.6673H3.11041L11.3019 5.47584L8.52449 2.69844L0.333008 10.8899ZM13.4497 2.28368L11.7166 0.55058C11.6481 0.48192 11.5667 0.427448 11.4771 0.390281C11.3875 0.353115 11.2915 0.333984 11.1945 0.333984C11.0975 0.333984 11.0014 0.353115 10.9118 0.390281C10.8223 0.427448 10.7409 0.48192 10.6723 0.55058L9.31697 1.90595L12.0944 4.68335L13.4497 3.32798C13.5184 3.25946 13.5729 3.17807 13.61 3.08848C13.6472 2.99888 13.6663 2.90283 13.6663 2.80583C13.6663 2.70883 13.6472 2.61278 13.61 2.52318C13.5729 2.43359 13.5184 2.3522 13.4497 2.28368Z"
                              fill="#51AF41"/>
                          </svg>
                        </a>
                        <a *ngIf="getAction(actionType.Delete) != null && !row.isDeleted" class="danger p-0"
                           (click)="delete(row)">
                          <!-- <i class="ft-x font-medium-3"></i> -->
                          <svg width="12" height="14" viewBox="0 0 12 14" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M2.35449 13.6673C1.95345 13.6673 1.61026 13.5224 1.32491 13.2325C1.03908 12.9421 0.896159 12.5932 0.896159 12.1858V2.55621C0.689562 2.55621 0.516263 2.48534 0.376263 2.34361C0.236749 2.20139 0.166992 2.02534 0.166992 1.81547C0.166992 1.60559 0.236749 1.42954 0.376263 1.28732C0.516263 1.14559 0.689562 1.07473 0.896159 1.07473H3.81283C3.81283 0.864849 3.88283 0.688799 4.02283 0.546577C4.16234 0.404849 4.33539 0.333984 4.54199 0.333984H7.45866C7.66526 0.333984 7.83855 0.404849 7.97855 0.546577C8.11807 0.688799 8.18783 0.864849 8.18783 1.07473H11.1045C11.3111 1.07473 11.4841 1.14559 11.6237 1.28732C11.7637 1.42954 11.8337 1.60559 11.8337 1.81547C11.8337 2.02534 11.7637 2.20139 11.6237 2.34361C11.4841 2.48534 11.3111 2.55621 11.1045 2.55621V12.1858C11.1045 12.5932 10.9618 12.9421 10.6765 13.2325C10.3906 13.5224 10.0472 13.6673 9.64616 13.6673H2.35449ZM3.81283 9.96361C3.81283 10.1735 3.88283 10.3493 4.02283 10.491C4.16234 10.6332 4.33539 10.7044 4.54199 10.7044C4.74859 10.7044 4.92189 10.6332 5.06189 10.491C5.2014 10.3493 5.27116 10.1735 5.27116 9.96361V4.77843C5.27116 4.56855 5.2014 4.3925 5.06189 4.25028C4.92189 4.10855 4.74859 4.03769 4.54199 4.03769C4.33539 4.03769 4.16234 4.10855 4.02283 4.25028C3.88283 4.3925 3.81283 4.56855 3.81283 4.77843V9.96361ZM6.72949 9.96361C6.72949 10.1735 6.79949 10.3493 6.93949 10.491C7.07901 10.6332 7.25206 10.7044 7.45866 10.7044C7.66526 10.7044 7.83855 10.6332 7.97855 10.491C8.11807 10.3493 8.18783 10.1735 8.18783 9.96361V4.77843C8.18783 4.56855 8.11807 4.3925 7.97855 4.25028C7.83855 4.10855 7.66526 4.03769 7.45866 4.03769C7.25206 4.03769 7.07901 4.10855 6.93949 4.25028C6.79949 4.3925 6.72949 4.56855 6.72949 4.77843V9.96361Z"
                              fill="#EE6464"/>
                          </svg>
                        </a>
                        <a *ngIf="getAction(actionType.Duplicate) != null" class="danger p-0" (click)="dublicate(row)">
                          <i class="ft-x font-medium-3"></i>
                        </a>
                      </td>
                    </ng-container>
                  </tr>
                  </tbody>
                </table>
              </div>

              <div *ngIf="pagination" class="d-flex p-2">

                <ngb-pagination (pageChange)="loadPage($event)" [collectionSize]="total" [(page)]="currentPage"
                                [maxSize]="4" [rotate]="true"
                                [ellipses]="true" [boundaryLinks]="true" [pageSize]="pageSize">
                </ngb-pagination>
                <div class="go-to-page d-flex ml-2">
                  <!-- <input type="number" class="form-control" placeholder="#" [(ngModel)]="pageInputValue" [ngModelOptions]="{standalone: true}"> -->
                  <!-- <button type="button" class="move btn btn-primary" (click)="goToPage()">Перейти</button> -->
                </div>
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="selection.selected.length">
    <span>
      {{'common.selected' | translate}} {{ selection.selected.length }} {{'common.of' | translate}} {{ rows.length }}.
      <a (click)="clearSelection()" href="javascript:void(0)">{{'common.cancel_entire_selection' | translate}}</a>
    </span>
  </div>
</section>
<!--table ends-->
